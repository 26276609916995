import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/get-organization-name';

const getNameOrganization = async (sigla) => {
    try {

        const response = await axios.post(`${baseUrl}${endpoint}`, {
            sigla: sigla
        });

        return {
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        console.error('Error fetching organization name:', error.response ? error.response.data : error.message);
        return {
            status: error.response ? error.response.status : 500,
            message: error.response ? error.response.data.message : 'Error fetching organization name.',
        };
    }
};

export { getNameOrganization };
