// src/components/LogosFooter.jsx
import React from 'react';
import { Row, Col } from 'antd';
import logoART from '../../assets/logos/logo_art.png';
import logoCampo from '../../assets/logos/logoCampo.png';
import logoFAO from '../../assets/logos/LogoFAO.png';
import logoPDET from '../../assets/logos/LogoPDET.png';
import './LogosFooter.css'; // Archivo CSS para estilos

const LogosFooter = () => {
    return (
        <div className="logos-footer">
            <Row gutter={[32, 32]} justify="center" align="middle">
                <Col xs={12} sm={6} md={4} lg={3}>
                    <a href="https://www.art.gov" target="_blank" rel="noopener noreferrer">
                        <img src={logoART} alt="ART Logo" className="logo-image" />
                    </a>
                </Col>
                <Col xs={12} sm={6} md={4} lg={3}>
                    <a href="https://www.fao.org" target="_blank" rel="noopener noreferrer">
                        <img src={logoFAO} alt="Logo FAO" className="logo-image" />
                    </a>
                </Col>
                <Col xs={12} sm={6} md={4} lg={3}>
                    <a href="https://www.art.gov" target="_blank" rel="noopener noreferrer">
                        <img src={logoCampo} alt="Logo Campo" className="logo-image" />
                    </a>
                </Col>
                <Col xs={12} sm={6} md={4} lg={3}>
                    <a href="https://www.renovacionterritorio.gov.co/especiales/especial_PDET/" target="_blank" rel="noopener noreferrer">
                        <img src={logoPDET} alt="Logo PDET" className="logo-image" />
                    </a>
                </Col>
            </Row>
        </div>
    );
};

export default LogosFooter;
