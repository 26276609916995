// services/dowloadProject.ts
import axios from 'axios';
import config from '../../config';
import Cookies from 'js-cookie';

// Configuración de la base URL y el endpoint
const baseUrl = config.baseUrl;
const downloadEndpoint = '/api/descargar-archivos';

// Crear una instancia de Axios para configurar base URL y otras opciones
const api = axios.create({
    baseURL: baseUrl,
});

// Interceptor de solicitud para añadir el token de autorización
api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Función para descargar un archivo o directorio
const downloadProject = async (ruta) => {
    try {
        const formData = new FormData();
        formData.append('ruta', ruta);

        const response = await api.post(downloadEndpoint, formData, {
            responseType: 'blob', // Indicar que la respuesta es un blob (archivo)
        });

        // Crear un objeto URL para el blob y crear un enlace de descarga
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${ruta}.zip`; // Nombre del archivo para descargar
        a.click();

        // Liberar el objeto URL creado
        window.URL.revokeObjectURL(url);
        
        return { success: true };
    } catch (error) {
        console.error('Error downloading project:', error.message || error);
        return {
            success: false,
            status: error.response ? error.response.status : 500,
            message: error.message || 'Error downloading project',
        };
    }
};

export default downloadProject;
