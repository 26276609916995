import axios from 'axios';
import config from '../../config';
import Cookies from 'js-cookie';

const baseUrl = config.baseUrl;
const projectEndpoint = '/api/getProjectsxmunicipio';
// Crear una instancia de Axios para configurar base URL y otras opciones
const api = axios.create({
  baseURL: config.baseUrl,
});
// Interceptor de solicitud para añadir el token de autorización
api.interceptors.request.use(
  (config) => {
    const token = Cookies.get('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Función para obtener proyectos
const getProyectosxmunicipio = async () => {
  try {
    const response = await api.get(`${baseUrl}${projectEndpoint}`);
    return {
      status: response.status,
      dataProyectosxmunicipio: response.data.proyectosxmunicipio
    };
  } catch (error) {
    // Manejo de errores
    console.error('Error fetching projects:', error.message || error);
    // Puedes personalizar el mensaje de error aquí
    return {
      status: error.response ? error.response.status : 500,
      message: error.message || 'Error fetching projects',
    };
  }
};

export default getProyectosxmunicipio;
