import axios from 'axios';
import config from '../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/arbolMer';
const getOrganizacionesMER = async () => {
    try {
        const response = await axios.get(`${baseUrl}${endpoint}`);
        return {
            status: response.data.status,
            data: response.data.Organizaciones
        };
    } catch (error) {
        console.error('Error fetching organizations:', error);
        return {
            status: error.response ? error.response.status : 500,
            message: 'Error fetching organizations'
        };
    }
};

export default getOrganizacionesMER;