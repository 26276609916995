import axios from 'axios';
import config from '../../config'; // Ajusta la ruta según tu estructura

const baseUrl = config.baseUrl;
//const baseUrl = 'http://localhost:5000';

// Función para obtener datos del mapa de un departamento específico

const replaceNullWithDash = (value) => (value === null || value === undefined) ? '-' : value;

const getMapDepartment = async (departmentId) => {

    try {
        const response = await axios.get(`${baseUrl}/api/mapDepartment/${departmentId}`);
        const data = response.data.data; // Ajusta según el formato de respuesta


        // Procesa los datos para los municipios del departamento seleccionado
        const processedData = data.map(muni => ({
            id: muni.codigoFusion, // Este debe ser el identificador único para FusionCharts
            value: muni.codigo_dane || '-', // El valor a mostrar
            label: muni.municipio, // El nombre del municipio
            shortLabel: muni.codigoFusion, // Código corto para el municipio
            nombreDepto: muni.nameDepto,
            color: '#4275af', // Color base de la región
            hoverBorderThickness: '2', // Grosor del borde en hover
            toolText: `<div style="font-family: Arial, sans-serif; font-size: 14px;">
            <table style="width: 100%; border-collapse: collapse; margin-left: auto; margin-right: auto;">
                <tbody>
                    <!-- Título del Municipio -->
                    <tr>
                        <td colspan="2" style="font-weight: bold; font-size: 16px; color: #333; padding-bottom: 10px; border-bottom: 2px solid #d0d0d0;">${muni.municipio} | ${replaceNullWithDash(muni.departamento)}</td>
                    </tr>
                    <!-- Departamento y Código DANE -->
                    <tr>
                    <td colspan="2" style="font-weight: bold; color: #0056b3; border-right: 2px solid #d0d0d0; padding-right: 10px;">
                         Proyectos:
                    <span style="color: #333;">  ${muni.total_proyectos}
                    </span>
                    
                    <ul style="margin: 0; padding-left: 15px; list-style-type: disc; color: #333;">
                    ${muni.listaproyectos ? muni.listaproyectos.split('</li><li>').map(p => `<li>${p.replace(/<\/?li>/g, '')}</li>`).join('') : '-'}
                            </ul>
                            
                            
                    </td>
                    
                    </tr>
                    <!-- Total Proyectos -->
                    <tr>
                        
                    </tr>
                    
                    <tr>
                        <td colspan="2" style="border-top: 2px solid #d0d0d0;">&nbsp;</td>
                    </tr>
                    <!-- Datos de Organizaciones -->
                    <tr>
                        <td colspan="2" style="text-align: left; font-weight: bold; color: #333; padding-bottom: 10px; border-bottom: 2px solid #d0d0d0;">Datos de Organizaciones</td>
                    </tr>
                   
                    <!-- Listado de Organizaciones -->
                    <tr>
                        <td colspan="2" style="font-weight: bold; color: #0056b3; padding-top: 10px; border-top: 2px solid #d0d0d0;">Organizaciones</td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <ul style="margin: 0; padding-left: 15px; list-style-type: disc;">
                                ${muni.siglas_organizaciones ? muni.siglas_organizaciones.split('</li><li>').map(o => `<li>${o.replace(/<\/?li>/g, '')}</li>`).join('') : '-'}
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>`
            ,
            link: muni.link || 'https://bodecom.com/', // Link opcional
        }));

        return {
            status: response.status,
            data: {
                data: processedData // Asegúrate de que este campo se corresponda con lo esperado por FusionCharts
            }
        };
    } catch (error) {
        console.error(`Error al obtener datos del mapa del departamento ${departmentId}:`, error);
        throw error;
    }
};

export { getMapDepartment };
