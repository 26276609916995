import React, { useState, useEffect } from 'react';
import { Drawer, Button, Select, Input, message } from 'antd';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import './insumoReportes.css'; // Asegúrate de que el archivo CSS esté importado
import { updateReport } from '../../services/inf_insumos/put_reports'; // Importa la función para actualizar el reporte
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';

const ReportDrawer = ({ open, onClose, reportData }) => {
  // Estado para manejar la selección del estado del reporte
  const [oidEstado, setOidEstado] = useState(reportData.oidEstado || ''); // Valor predeterminado vacío
  const [selectedStatus, setSelectedStatus] = useState(reportData.oidEstado || ''); // Valor predeterminado vacío
  const [periodoText, setPeriodoText] = useState(reportData.periodo || ''); // Estado para el TextArea del periodo

  //Control de Permisos
  const appName = 'reportes'; // El nombre de la aplicación actual
  const permissions = useSelector((state) => selectAppPermissions(state, appName));
  const permissionUpdate = permissions.priv_update === 'Y';



  const { TextArea } = Input;

  // Opciones del select
  const statusOptions = [
    { value: '', label: 'Selecciona un estado' }, // Valor vacío como opción predeterminada
    { value: '1', label: 'Entregado' },
    { value: '2', label: 'Pendiente de Revisión' },
    { value: '3', label: 'Programado' },
  ];

  // Efecto para actualizar el valor del estado y los textos de los TextAreas cuando reportData cambia
  useEffect(() => {
    setOidEstado(reportData.oidEstado || ''); // Inicializa el estado con el ID del estado del reporte
    setSelectedStatus(reportData.estado || ''); // Inicializa el select con el estado del reporte
    setPeriodoText(reportData.periodo || ''); // Inicializa el TextArea con el periodo
  }, [reportData.estado, reportData.periodo,  reportData.oidEstado]);

  /**
   * Manejador del cambio en el select
   * @param {string} value - El nuevo valor seleccionado del estado
   */
  const handleStatusChange = (value) => {
    setOidEstado(value); // Actualiza el estado del ID del estado
    setSelectedStatus(value); // Actualiza el estado seleccionado
  };

  // Manejador del cambio en el TextArea del periodo
  const handlePeriodoChange = (e) => {
    setPeriodoText(e.target.value); // Actualiza el estado del TextArea del periodo
  };



  // Actualiza el reporte con el nuevo estado seleccionado
  const handleUpdate = async () => {
    try {
      // Llama al servicio para actualizar el reporte
      await updateReport(reportData.oid, oidEstado, periodoText);
      message.success('Reporte actualizado');
      onClose(); // Cierra el Drawer después de actualizar
    } catch (error) {
      console.error('Error al actualizar el reporte:', error);
    }
  };

  return (
    <Drawer
      title={`Actualizar ${reportData.nombre_modulo}`}
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      className="custom-drawer" // Aplica la clase CSS aquí
    >
      <p><strong>Estado Actual:</strong></p>
      <Select
        value={selectedStatus}
        onChange={handleStatusChange} // Llama a handleStatusChange al cambiar la selección
        style={{ width: '100%', marginBottom: '20px' }}
        placeholder="Selecciona un estado" // Texto que se muestra cuando no hay una opción seleccionada
        options={statusOptions}
      />
      <p><strong>Periodo:</strong></p>
      <TextArea
        showCount
        style={{ marginBottom: '20px' }}
        maxLength={100}
        placeholder="Periodo"
        value={periodoText} // Asigna el estado del TextArea aquí
        onChange={handlePeriodoChange} // Maneja el cambio en el TextArea
      />

      <div style={{ marginTop: '16px' }}>

        {permissionUpdate && (
          <Button
            type="primary"
            onClick={handleUpdate} // Llama a handleUpdate al hacer clic
            style={{ marginRight: '8px' }}
            icon={<SaveOutlined />} // Icono de guardar
          >
            Actualizar
          </Button>

        )}
        <Button
          danger
          onClick={onClose} // Llama a onClose al hacer clic
          icon={<CloseOutlined />} // Icono de cancelar
        >
          Cancelar
        </Button>
      </div>
    </Drawer>
  );
};

export default ReportDrawer;
