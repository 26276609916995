import React from 'react';

function ActualizarProyecto() {
    return (
        <div>
            <h1>Actualizar Proyecto</h1>
            <p>Esta página te permite actualizar la información de un proyecto.</p>
        </div>
    );
}

export default ActualizarProyecto;
