const config = {
  baseUrl: process.env.REACT_APP_BASE_URL || 'https://bodecom.com',
  excelProyectosPorOrganizaciones: process.env.REACT_APP_EXCEL_PROYECTOS_POR_ORGANIZACIONES || 'https://bodecom.com',
  baseDirUrl: '/var/www/html/backend/DocumentosFAO/DocumentosPOA/actividad_41/tarea_414/indicador_73/medio_verificacion_68',
  baseDirEstructuracion: process.env.REACT_BASE_ESTRUCTURACION_URL || '/Volumes/bodecom/bodecom_160/backend/DocumentosFAO/DocumentosPOA/actividad_13',
};
export default config;





/*
const config = {
  baseUrl: 'http://localhost:5000',
  excelProyectosPorOrganizaciones: process.env.REACT_APP_EXCEL_PROYECTOS_POR_ORGANIZACIONES || 'defaultExcelUrl',
  baseDirUrl:  'D:/Comunix/bodecom_160/backend/DocumentosFAO/DocumentosPOA/actividad_41/tarea_414/indicador_73/medio_verificacion_68',
  baseDirEstructuracion: process.env.REACT_BASE_ESTRUCTURACION_URL || '/Volumes/bodecom/bodecom_160/backend/DocumentosFAO/DocumentosPOA/actividad_13',

};

//console.log('config:', config);
export default config;
*/