import React, { useState, useEffect } from 'react';
import { Tree, DatePicker, Modal, Button, Spin, Layout, message, Tabs, Statistic, Progress, Timeline, Space, Flex, Input, Row, Col, Breadcrumb, Tooltip } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, ExpandAltOutlined, ShrinkOutlined, SearchOutlined, ReloadOutlined, EditOutlined } from '@ant-design/icons';
import Header160 from '../header/header';
import { cloneDeep } from 'lodash';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './Poa.css';
import CountUp from 'react-countup';
import PoaActividades from './PoaActividades';
import ExportPoa from './ExportPoa';
import { fetchPoaData, fetchPoaDataWithParams } from '../../services/poa/get_poa';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import { updatePoaCorteFecha, fetchPoaCorteData } from '../../services/poa/poaCorteService'; // Asegúrate de ajustar la ruta según tu estructura de proyecto
import moment from 'moment';

const { Content, Sider } = Layout;
const { TabPane } = Tabs;

const Poa = () => {
    const [treeData, setTreeData] = useState([]);
    const [dataCompleta, setDataCompleta] = useState([]); // Estado para la data completa
    const [selectedItem, setSelectedItem] = useState(null);
    const [fechaCorte, setFechaCorte] = useState(null);
    const [loading, setLoading] = useState(true);
    const [expandAll, setExpandAll] = useState(false);
    const [showCards, setShowCards] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newFechaCorte, setNewFechaCorte] = useState(dayjs(fechaCorte, 'YYYY-MM-DD'));
    const [controlExpandallC, setControlExpandallC] = useState(true);
    const [treeVisible, setTreeVisible] = useState(true);
    const [collapsed, setCollapsed] = useState(false);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const onExpand = (newExpandedKeys) => { setExpandedKeys(newExpandedKeys); setAutoExpandParent(true); };
    const [oidTarea, setOidTarea] = useState(selectedItem ? selectedItem.oid_tarea : null);

    const [fechaNueva, setFechaNueva] = useState(''); // Estado para la nueva fecha de corte a actualizar


    const appName = 'poa'; // El nombre de la aplicación actual
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionExport = permissions.priv_export === 'Y';

    const toggleTreeVisibility = () => {
        setCollapsed(!collapsed);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                loadPoaCorteData();
                const dataCompleta = await fetchPoaData();
                const { dataPoa, fechaCorte } = dataCompleta;
                setDataCompleta(dataCompleta);
                const data = dataPoa;
                // Extrae y formatea la fecha
                if (fechaCorte && fechaCorte.length > 0) {
                    const fecha = fechaCorte[0]?.fecha;
                    if (fecha) {
                        const formattedFechaCorte = formatDateToLocal(fecha);
                        setFechaCorte(formattedFechaCorte);
                        setNewFechaCorte(dayjs(fecha, 'YYYY-MM-DD'));
                    }
                }

                // Filtrar los nodos hasta el tercer nivel
                const filteredData = data.map(item => {
                    // Filtrar los hijos de primer nivel (segundo nivel)
                    if (item.children && item.children.length > 0) {
                        item.children = item.children.map(child => {
                            // Filtrar los hijos de segundo nivel (tercer nivel)
                            if (child.children && child.children.length > 0) {
                                child.children = child.children.filter(grandchild => !grandchild.children);
                            }
                            return child;
                        });
                    }

                    return item;
                });

                setTreeData(filteredData);

                // Siempre expande el nodo raíz '0' al cargar los datos
                setExpandedKeys(keys => {
                    if (!keys.includes('0')) {
                        return [...keys, '0', 'producto_1'];
                    }
                    return [...keys, 'producto_1'];
                });


                if (!expandAll && data.length > 0) {
                    const initialKeysToExpand = data.slice(0, 9).map(item => item.key);
                    setExpandedKeys(initialKeysToExpand);
                }

            } catch (error) {
                console.error('Error fetching file tree:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [expandAll]);



    // Función para mostrar el modal
    const showModal = () => {
        setIsModalVisible(true);
    };

    // Función para manejar el cierre del modal
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // Función para manejar el cambio en el DatePicker
    const handleDateChange = (date) => {
        if (date && date.isValid()) {
            setNewFechaCorte(date);
        } else {
            console.error('Fecha inválida');
            // Establece una fecha predeterminada o muestra un mensaje de error si la fecha es inválida
            setNewFechaCorte(dayjs()); // Establece una fecha predeterminada si es necesario
        }
    };


    // Función para guardar los cambios
    const handleSave = async () => {
        try {
            if (!newFechaCorte || !newFechaCorte.isValid()) {
                message.error('La fecha es obligatoria y debe ser válida');
                return;
            }
            setFechaCorte(newFechaCorte.format('DD/MM/YYYY'));
            message.success('Fecha de corte actualizada correctamente');
        } catch (error) {
            console.error('Error al guardar la fecha de corte:', error);
            message.error('Error al actualizar la fecha de corte');
        } finally {
            setIsModalVisible(false);
        }
    };

    const clearHighlight = (nodes) => {
        nodes.forEach(node => {
            node.highlighted = false; // Establece todos los nodos como no destacados
            if (node.children) {
                clearHighlight(node.children); // Recursivamente limpia los hijos si existen
            }
        });
    };

    const getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some((item) => item.key === key)) {
                    parentKey = node.key;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    const toggleExpandAll = () => {
        setExpandAll(!expandAll); // Invierte el estado de expansión global

        if (!expandAll) {

            // Expandir todos los nodos


            const keysToExpand = getAllKeysToExpand(treeData, 2, 'Participantes');
            setExpandedKeys(keysToExpand);

        } else {

            // Contraer todos los nodos excepto el nodo '0'
            setExpandedKeys(['0']); // Asegura que el nodo '0' esté expandido explícitamente
        }
    };

    const getAllKeysToExpand = (data, level, excludedName) => {
        let keys = [];
        const traverse = (nodes, currentLevel) => {
            if (currentLevel > level) return;
            nodes.forEach(node => {
                if (node.name !== excludedName) {
                    keys.push(node.key);
                    if (node.children) {
                        traverse(node.children, currentLevel + 1);
                    }
                }
            });
        };
        traverse(data, 1);
        return keys;
    };



    const handleSelect = async (selectedKeys, info) => {
        const selectedNode = info.node;
        // Permitir la selección solo si el nodo es de tipo 'Producto'
        if (selectedNode.tipo === 'Producto') {
            try {
                // Realizar la solicitud al backend

                const data = await fetchPoaDataWithParams(selectedNode.key);
                // Verificar la respuesta
                if (data.status === 'success') {
                    const nodeFromDataCompleta = findNodeInData(data.dataPoa, selectedNode.key);

                    if (nodeFromDataCompleta) {
                        // Configurar selectedItem con el nodo encontrado en la respuesta
                        setSelectedItem(nodeFromDataCompleta);
                    }

                    setShowCards(false); // Mostrar las tarjetas si es necesario
                } else {
                    console.error('Error en la respuesta del backend:', data.message);
                    setSelectedItem(null);
                    setShowCards(false);
                }
            } catch (error) {
                console.error('Error en la solicitud al backend:', error);
                setSelectedItem(null);
                setShowCards(false);
            }
        } else {
            message.warning('Solo se pueden seleccionar elementos de tipo "Producto".');
        }
    };

    // Función para actualizar el item seleccionado desde el hijo
    const actualizarSelectedItem = async () => {
        if (selectedItem) {
            try {
                const data = await fetchPoaDataWithParams(selectedItem.key);
                if (data.status === 'success') {
                    const nodeFromDataCompleta = findNodeInData(data.dataPoa, selectedItem.key);
                    if (nodeFromDataCompleta) {
                        setSelectedItem(nodeFromDataCompleta);
                    }
                } else {
                    console.error('Error en la respuesta del backend:', data.message);
                }
            } catch (error) {
                console.error('Error en la solicitud al backend:', error);
            }
        }
    };


    const findNodeInData = (data, key) => {
        for (let item of data) {
            if (item.key === key) {
                return item;
            } else if (item.children) {
                const found = findNodeInData(item.children, key);
                if (found) return found;
            }
        }
        return null;
    };


    const toggleShowCards = () => {
        setShowCards(!showCards);
    };

    const toggleExpandC = () => {
        setControlExpandallC(prevState => !prevState);
    };




    const findNode = (nodes, key) => {
        for (const node of nodes) {
            pathToNode.push(node);
            if (node.key === key) {
                return true;
            }
            if (node.children && findNode(node.children, key)) {
                return true;
            }
            pathToNode.pop();
        }
        return false;
    };


    const titleRenderer = (node) => {
        const tooltipTitle = node?.producto || node?.actividad || node?.tarea || node?.indicador_meta || node?.title || 'Sin título';

        return (
            <Tooltip title={tooltipTitle} color='#87d068' placement="right">
                <span className={node.highlighted ? 'highlightedNode' : ''}>{node.title}</span>
            </Tooltip>
        );
    };


    // Define la función findPathToNode antes de usarla
    const findPathToNode = (treeData, key) => {
        const path = [];

        const findNode = (nodes, key) => {
            for (const node of nodes) {
                path.push(node);
                if (node.key === key) {
                    return true;
                }
                if (node.children && findNode(node.children, key)) {
                    return true;
                }
                path.pop();
            }
            return false;
        };

        findNode(treeData, key);
        return path;
    };

    const pathToNode = selectedItem ? findPathToNode(treeData, selectedItem.key) : [];

    const breadcrumbItems = pathToNode.map((node, index) => ({
        key: String(index),
        content: node.title,
    }));


    const calcularSumaMetaE = (metaEjecutadaArray) => {
        return metaEjecutadaArray.reduce((total, meta) => total + meta.valor, 0);
    };

    const calcularPorcentajeAvance = (sumaMetaEjecutada, metaProyectada) => {
        if (metaProyectada === 0) {
            return 0; // Evitar división por cero
        }
        const porcentaje = (sumaMetaEjecutada / metaProyectada) * 100;
        return porcentaje.toFixed(2); // Redondear el porcentaje a 2 decimales
    };

    //Función para formatear la fecha a local
    function formatDateToLocal(fechaUTC) {
        const fecha = new Date(fechaUTC);
        const dia = String(fecha.getUTCDate()).padStart(2, '0');
        const mes = String(fecha.getUTCMonth() + 1).padStart(2, '0'); // Los meses en JavaScript van de 0 a 11
        const año = fecha.getUTCFullYear();
        return `${dia}/${mes}/${año}`;
      }
    // Función para cargar los datos de poaCorte
    const loadPoaCorteData = async () => {
        try {
            const data = await fetchPoaCorteData();
            if (data.length > 0) {
                const registro = data.find((item) => item.oid === 1);
                if (registro) {
                    const formattedFechaCorte = formatDateToLocal(registro.fecha);
                    setFechaCorte(formattedFechaCorte); // Formatear la fecha con moment
                    setNewFechaCorte(dayjs(registro.fecha, 'YYYY-MM-DD'));
                } else {
                    message.error('No se encontró el registro con OID 1.');
                }
            } else {
                message.warning('No hay datos disponibles.');
            }
        } catch (error) {
            console.error('Error al obtener los datos de poaCorte:', error.message);
            message.error('Error al obtener los datos.');
        }
    };
    // Guardar la nueva fecha
    const handleSaveDate = async () => {
        if (!newFechaCorte) {
            message.warning('Por favor, selecciona una fecha.');
            return;
        }

        try {
            await updatePoaCorteFecha(1, newFechaCorte.format('YYYY-MM-DD')); // Llamada al servicio para actualizar
            message.success('Fecha de corte actualizada exitosamente');
            setIsModalVisible(false);
            loadPoaCorteData();
        } catch (error) {
            console.error('Error al actualizar la fecha de corte:', error.message);
            message.error('Error al actualizar la fecha.');
        }
    };

    return (
        <Layout className="container">
            {loading ? (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            ) : (
                <div className="container py-3">
                    <div className="row mb-2 d-flex align-items-center">
                        <Header160 />
                        <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                            <h2 className="text-center mb-2">UTF COL 160 COL</h2>
                            <h4>Fecha del corte: <span>{fechaCorte}</span></h4>
                            <div className="d-flex justify-content-center align-items-center gap-2">
                                {permissionUpdate && (
                                    <Tooltip title="Editar la fecha de corte">
                                        <Button
                                            type="primary"
                                            icon={<EditOutlined />}
                                            onClick={showModal}
                                            shape="circle"
                                            size="large"
                                        />
                                    </Tooltip>
                                )}

                                {permissionExport && (<Tooltip title="Exportar en Excel el POA"><ExportPoa /></Tooltip>)}
                            </div>
                            <Modal
                                title="Editar Fecha de Corte"
                                open={isModalVisible}
                                onOk={handleSaveDate}
                                onCancel={handleCancel}
                            >
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    value={newFechaCorte}
                                    onChange={handleDateChange}
                                    style={{ width: '100%' }}
                                />
                            </Modal>
                        </div>

                    </div>
                    <div style={{ marginBottom: 8, textAlign: 'left' }}>
                        <h6 onClick={toggleTreeVisibility} style={{ cursor: 'pointer' }}>
                            POA: UTF COL 160 |
                            {collapsed ? <MenuUnfoldOutlined style={{ color: '#1677ff', paddingLeft: '5px' }} /> : <MenuFoldOutlined style={{ color: '#1677ff', paddingLeft: '5px' }} />}
                        </h6>
                    </div>
                    <Layout style={{ minHeight: '100vh' }}>
                        <Sider
                            width={230}
                            collapsedWidth={0}
                            className="site-layout-background"
                            collapsed={collapsed}
                            collapsible
                            trigger={null}
                            onCollapse={toggleTreeVisibility}
                            style={{ transition: 'all 0.3s' }}
                        >

                            {!collapsed && (
                                <Tree
                                    treeData={treeData}
                                    expandedKeys={expandedKeys}
                                    onExpand={onExpand}
                                    onSelect={handleSelect}
                                    autoExpandParent={autoExpandParent}
                                    titleRender={titleRenderer}
                                    showLine={true}
                                />
                            )}
                        </Sider>
                        <Layout
                            style={{
                                flex: 1,
                                padding: collapsed ? '0px 0px 0px' : '0px 24px 24px',
                                transition: 'padding 0.3s'
                            }}
                        >
                            <Content
                                className="site-layout-background"
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    minHeight: 200,
                                }}
                            >
                                {selectedItem && (
                                    <Space direction="vertical" style={{ width: '100%', display: 'block !important' }} size="large">
                                        <Row gutter={8} justify="center" align="middle">
                                            <Col span={24}>
                                                <div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                                                            {selectedItem ?
                                                                (selectedItem.producto || selectedItem.actividad || selectedItem.tarea || selectedItem.indicador || selectedItem.title || 'Sin título')
                                                                : 'Sin elemento seleccionado'}
                                                        </div>
                                                        <div style={{ flex: '0 0 auto' }}>
                                                            <button
                                                                onClick={toggleShowCards}
                                                                className="btn py-0 m-0"
                                                                style={{ fontSize: '15px', color: '#8dc145' }}
                                                            >
                                                                <FontAwesomeIcon icon={showCards ? faInfoCircle : faQuestionCircle} style={{ marginLeft: '2px' }} />
                                                            </button>
                                                            <button
                                                                onClick={toggleExpandC}
                                                                className="btn py-0 m-0"
                                                                style={{ fontSize: '15px', color: '#1677ff', marginLeft: '5px' }}
                                                            >
                                                                {controlExpandallC ? <ExpandAltOutlined /> : <ShrinkOutlined />}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        {showCards && pathToNode.map((node, index) => (
                                                            node && (
                                                                <Card
                                                                    key={index}
                                                                    className={`m-2 ${showCards ? 'card-transition' : 'card-hidden'}`}
                                                                    style={{ width: '300px', overflowY: 'auto' }}
                                                                >
                                                                    <Card.Body>
                                                                        <Card.Title style={{ fontSize: '14px' }}>{node.producto || node.actividad || node.tarea || node.indicador || node.title || 'Sin título'}</Card.Title>
                                                                        {node.tipo === 'Fase' && <Card.Text>Fase: {node.title}</Card.Text>}
                                                                        {node.producto && <Card.Text>Producto: {node.codigo_producto}</Card.Text>}
                                                                        {node.actividad && <Card.Text>Código Actividad: {node.codigo_actividad}</Card.Text>}
                                                                        {node.tarea && <Card.Text>Código Tarea: {node.codigo_tarea}</Card.Text>}
                                                                        {node.tipo === 'Indicador' && <Card.Text>Código Indicador: {node.key}</Card.Text>}
                                                                    </Card.Body>
                                                                </Card>
                                                            )
                                                        ))}
                                                    </div>
                                                    <PoaActividades nodo={selectedItem} actualizar={actualizarSelectedItem} expandAllC={controlExpandallC} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Space>
                                )}
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            )
            }
        </Layout >
    );
};

export default Poa;
