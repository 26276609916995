import React, { useEffect, useState } from 'react';
import { Button, Input, Row, Col, Tooltip, Radio, Form, Select, InputNumber, Modal, Space, Table, Alert, Spin, notification, message } from 'antd';
import { DownloadOutlined, PlusOutlined} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExclamationCircle, faFile  } from '@fortawesome/free-solid-svg-icons';
import 'antd/dist/reset.css';
import { SearchOutlined, InboxOutlined, ClearOutlined, LeftOutlined } from '@ant-design/icons';
import './Organizaciones.css';
import * as XLSX from 'xlsx';
import { FaTable, FaList } from 'react-icons/fa';
import { useLocation, Link } from 'react-router-dom';
//Servicios
import getOrganizaciones from '../../services/organization/organizacionesServices';
import getTiposOrganizacion from '../../services/organization/tiposOrganizacionesServices';
import getDeptosMunicipios from '../../services/departMunic/deptosMunicipiosServices';
import { updateOrganizacion } from '../../services/organization/organizacionService'; // Asegúrate de importar tu servicio
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import UploadImage from '../SoportesMer/UploadImage';

const Organizaciones = () => {
    const [organizaciones, setOrganizaciones] = useState([]);
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({ pageSize: 30, current: 1 });
    const [sortOrder, setSortOrder] = useState({});
    const [sortField, setSortField] = useState('');
    const [departamentosFilters, setDepartamentosFilters] = useState([]);
    const [tiposOrganizacionFilters, setTiposOrganizacionFilters] = useState([]);
    const [OrganizacionMujeresFilters, setOrganizacionMujeresFilters] = useState([]);
    const [OrganizacionNitFilters, setOrganizacionNitFilters] = useState([]);
    const [ProyectosFilters, setProyectosFilters] = useState([]);
    const [OrganizacionesNameFilters, setOrganizacionesNameFilters] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [searchTerm, setSearchTerm] = useState(''); // Agrega este estado
    const [tiposOrganizacion, setTiposOrganizacion] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [count, setCount] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [currentOrg, setCurrentOrg] = useState({});
    const [isMunicipiosDisabled, setIsMunicipiosDisabled] = useState(true);
    const [departamentos, setDepartamentos] = useState([]);
    const [municipiosPorDepartamento, setMunicipiosPorDepartamento] = useState({});
    const [selectedDepartamento, setSelectedDepartamento] = useState(null);
    const [selectedMunicipios, setSelectedMunicipios] = useState([]);
    const [viewMode, setViewMode] = useState('table'); // Estado para manejar el modo de vista
    const [treeData, setTreeData] = useState([]);
    const [orgOrganizaciones, setOrgOrganizaciones] = useState(null); // Para el oid seleccionado
    const [orgMujeres, setOrgMujeres] = useState(null); // Para el valor 1 o 2
    const [nombreAMunicipioOID, setNombreAMunicipioOID] = useState({}); // Mapeo de nombres de municipios a OIDs
    //Control de Permisos
    const appName = 'organizaciones'; // El nombre de la aplicación actual
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionDelete = permissions.priv_delete === 'Y';
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionInsert = permissions.priv_insert === 'Y';
    const [isModalVisibleSoportes, setIsModalVisibleSoportes] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalOrgId, setOrgId] = useState("");
    const [modalSupportId, setSupportId] = useState("");
    const [modalInitialsOrg, setInitialsOrg] = useState("");

    const handleClickTable = () => {
        setViewMode('table'); // Actualiza el modo a 'table'
    };

    const handleClickTree = () => {
        setViewMode('tree'); // Actualiza el modo a 'tree'
    };

    const location = useLocation();
    const { oid_proyecto_busqueda } = location.state || {};

    useEffect(() => {
        fetchOrganizaciones();
        fetchTiposOrganizacion();
        cargarDeptosMunicipios();
    }, [oid_proyecto_busqueda]);


    const fetchOrganizaciones = async () => {
        try {
            const { status, data } = await getOrganizaciones();
            setStatus(status);
            if (status === 200) {
                let filteredData = data;
                // Verificar si oid_proyecto_busqueda está presente y filtrar datos
                if (oid_proyecto_busqueda) {
                    filteredData = data.filter(org => org.oid_proyecto === parseInt(oid_proyecto_busqueda));
                }
                setOrganizaciones(filteredData || []);
                // Obtener departamentos únicos para filtros
                const uniqueDepartamentos = [...new Set(filteredData.map(org => org.departamentos))];
                const filtersDepartamentos = uniqueDepartamentos.map(dep => ({ text: dep, value: dep }));
                setDepartamentosFilters(filtersDepartamentos);
                // Obtener tipos de organización únicos para filtros
                const uniqueTiposOrganizacion = [...new Set(filteredData.map(org => org.tipo_organizacion))];
                const filtersTiposOrganizacion = uniqueTiposOrganizacion.map(tipo => ({ text: tipo, value: tipo }));
                setTiposOrganizacionFilters(filtersTiposOrganizacion);
                // Obtener organizaciones de mujeres únicas para filtros
                const uniqueMujeresOrganizacion = [...new Set(filteredData.map(org => org.org_mujeres))];
                const filtersOrganizacionMujeres = uniqueMujeresOrganizacion.map(tipo => ({ text: tipo, value: tipo }));
                setOrganizacionMujeresFilters(filtersOrganizacionMujeres);
                // Obtener proyectos únicos para filtros
                const uniqueProyectos = [...new Set(filteredData.map(org => org.proyecto_detalle))];
                const filtersProyectos = uniqueProyectos.map(tipo => ({ text: tipo, value: tipo }));
                setProyectosFilters(filtersProyectos);
                // Obtener NIT único para filtros
                const uniqueNit = [...new Set(filteredData.map(org => org.Nit))];
                const filtersNit = uniqueNit.map(tipo => ({ text: tipo, value: tipo }));
                setOrganizacionNitFilters(filtersNit);
                // Obtener nombres de organizaciones únicos para filtros
                const uniqueName = [...new Set(filteredData.map(org => org.organizacion))];
                const filtersName = uniqueName.map(tipo => ({ text: tipo, value: tipo }));
                setOrganizacionesNameFilters(filtersName);
            } else {
                setOrganizaciones([]);
            }
        } catch (error) {
            console.error('Error fetching organizations:', error);
            setStatus(500);
            setOrganizaciones([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (viewMode === 'tree') {
            const treeData = transformToTreeData(organizaciones, searchTerm);
            setTreeData(treeData);
        }
    }, [viewMode, organizaciones, searchTerm]);


    const transformToTreeData = (organizaciones, searchTerm = '') => {
        const projectsMap = {};

        organizaciones.forEach((org) => {
            const { oid_proyecto, proyecto_detalle, ...rest } = org;
            if (!projectsMap[oid_proyecto]) {
                projectsMap[oid_proyecto] = {
                    key: `project-${oid_proyecto}`,
                    oid_proyecto,
                    proyecto_detalle,
                    children: [],
                    filtered: false,
                };
            }

            const childNode = {
                key: `project-${oid_proyecto}-org-${org.oid}`, // Clave única
                ...rest,
                filtered: searchTerm ? org.proyecto_detalle.toLowerCase().includes(searchTerm) : true,
            };

            projectsMap[oid_proyecto].children.push(childNode);

            if (childNode.filtered) {
                projectsMap[oid_proyecto].filtered = true;
            }
        });

        return Object.values(projectsMap).filter(project => project.filtered);
    };

    const showModal = () => {
        let newOid;
        if (!editMode) {
            newOid = organizaciones.length > 0 ? Math.max(...organizaciones.map(org => org.oid)) + 1 : 1;
        } else {
            newOid = currentOrg ? currentOrg.oid : null; // Verificar si currentOrg está definido
        }

        setEditMode(false); // Cambiar a modo nuevo
        setCurrentOrg({ oid: newOid }); // Establecer el nuevo OID en el estado
        form.resetFields(); // Resetear los campos del formulario
        setIsMunicipiosDisabled(true); // Deshabilitar el campo de municipios inicialmente
        form.setFieldsValue({ oid: newOid }); // Rellenar el campo OID en el formulario
        setIsModalVisible(true); // Mostrar el modal
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
        setEditMode(false);
        setCurrentOrg(null);
    };

    const handleTableChange = (pagination, filters, sorter, extra) => {
        setPagination(pagination);
        setSortOrder(sorter.order);
        setSortField(sorter.field);
        setFilteredInfo(filters);
    };

    const handleAdd = (values) => {

        const municipiosNombres = values.municipios.map(oid => municipiosPorDepartamento[selectedDepartamento].find(m => m.value === oid).label).join(', ');
        const departamentoNombre = departamentos.find(depto => depto.oid_departamento === values.departamentos)?.nombre_departamento;
        const totalParticipantes = values.n_hombres + values.n_mujeres;
        const newOid = organizaciones.length > 0 ? Math.max(...organizaciones.map(org => org.oid)) + 1 : 1;
        const newOrganization = {
            ...values,
            oid: newOid,
            key: `org-${newOid}`,
            municipios: municipiosNombres,
            departamentos: departamentoNombre,
            total_participantes: totalParticipantes
        };

        // Agregar el nuevo registro al inicio de la tabla
        setOrganizaciones([newOrganization, ...organizaciones]);
        setCount(count + 1);
        openNotificationWithIcon('success');
        setIsModalVisible(false);
        form.resetFields();
    };


    const openNotificationWithIcon = (type) => {
        notification.success({
            message: 'Registro creado',
            description: 'El registro se ha creado correctamente.',
            duration: 5,
            onClose: () => {
                setIsModalVisible(false);
                form.resetFields();
            },
        });
    };

    const handleEdit = async (values) => {
        const tipoOrganizacionValor = values.tipo_organizacion;
        // Verifica si es un número
        const tipoOrganizacionOID = !isNaN(tipoOrganizacionValor)
            ? parseInt(tipoOrganizacionValor, 10) // Convierte el texto a número si es necesario
            : tiposOrganizacion.find(t => t.tipo_organizacion === tipoOrganizacionValor)?.oid;

        const orgMujeresOID = values.org_mujeres === 'No' ? 0 : 1;
        // Convierte nombres de municipios a OIDs

        const municipiosOIDs = values.municipios.map(val =>
            !isNaN(val) ? val : nombreAMunicipioOID[val] // Convierte nombres a OIDs si es necesario
        ).filter(oid => oid !== undefined); // Filtra valores indefinidos si algún nombre no se encuentra
        const updatedValues = {
            oid: currentOrg.oid,
            Nit: values.Nit,
            organizacion: values.organizacion,
            sigla: values.sigla,
            tipo_organizacion: tipoOrganizacionOID, // Debe ser OID
            n_hombres: values.n_hombres,
            n_mujeres: values.n_mujeres,
            org_mujeres: orgMujeresOID, // Debe ser 0 o 1
            oid_proyecto: values.oid_proyecto,
            nivel: 1,
            municipios: municipiosOIDs, // Array de OIDs
        };
        // Realizar la llamada al servicio para actualizar en el backend
        try {
            await updateOrganizacion(updatedValues); // Llama al servicio creado previamente con updatedValues
            message.success('Organización actualizada correctamente.');
            fetchOrganizaciones();


        } catch (error) {
            console.error('Error al actualizar en el backend:', error);
        }

        setIsModalVisible(false);
        form.resetFields();
        setEditMode(false);
        setCurrentOrg(null);

    };



    const fetchTiposOrganizacion = async () => {
        try {
            const response = await getTiposOrganizacion();
            if (response.status === 200) {
                setTiposOrganizacion(response.data); // Asumiendo que response.data es un arreglo de tipos de organización
            } else {
                console.error('Error fetching organization types:', response.message);
            }
        } catch (error) {
            console.error('Error fetching organization types:', error);
        }
    };

    const cargarDeptosMunicipios = async () => {
        try {
            const response = await getDeptosMunicipios();
            if (response.status === 200) {
                setDepartamentos(response.data);
                // Crea un mapa de municipios por departamento
                const municipiosMap = {};
                const nombreAMunicipioOID = {};
                response.data.forEach(depto => {
                    municipiosMap[depto.oid_departamento] = depto.municipios.map(municipio => {
                        nombreAMunicipioOID[municipio.nombre_municipio] = municipio.oid_municipio;
                        return {
                            value: municipio.oid_municipio,
                            label: municipio.nombre_municipio
                        };
                    });
                });
                setMunicipiosPorDepartamento(municipiosMap);
                setNombreAMunicipioOID(nombreAMunicipioOID);
            } else {
                console.error('Error al obtener departamentos y municipios:', response.message);
            }
        } catch (error) {
            console.error('Error al obtener departamentos y municipios:', error);
        }
    };

    const handleDepartamentoChange = value => {
        setSelectedDepartamento(value);
        setSelectedMunicipios([]);
        form.setFieldsValue({ municipios: [] });
        setIsMunicipiosDisabled(false);

    };

    const handleMunicipiosChange = checkedValues => {
        setSelectedMunicipios(checkedValues);
    };

    const showEditModal = (record) => {
        setEditMode(true);
        setCurrentOrg(record);
        setIsMunicipiosDisabled(true);
        const totalParticipantes = record.n_hombres + record.n_mujeres;
        const municipiosIniciales = record.municipios.split(',').map(m => m.trim());

        // Inicializar proyectoDetalle como undefined
        let proyectoDetalle = record.proyecto_detalle;

        // Verificar si el registro tiene un key válido y comienza con 'org-'
        if (!proyectoDetalle && record.key && record.key.includes('org-')) {
            // Buscar el proyecto padre en treeData
            treeData.forEach(project => {
                if (project.children) {
                    project.children.forEach(org => {
                        if (org.key === record.key) {
                            proyectoDetalle = project.proyecto_detalle;
                        }
                    });
                }
            });
        }

        form.setFieldsValue({
            oid: record.oid,
            Nit: record.Nit,
            organizacion: record.organizacion,
            sigla: record.sigla,
            tipo_organizacion: record.tipo_organizacion,
            n_hombres: record.n_hombres,
            n_mujeres: record.n_mujeres,
            total_participantes: totalParticipantes,
            org_mujeres: record.org_mujeres,
            proyecto_detalle: proyectoDetalle,
            oid_proyecto: record.oid_proyecto,
            municipios: municipiosIniciales,
            departamentos: record.departamentos,
        });

        setIsModalVisible(true);
    };

    const handleSearchInputChange = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);

        if (viewMode === 'tree') {
            const treeData = transformToTreeData(organizaciones, searchTerm);
            setTreeData(treeData);
        } else {
            const filteredOrganizaciones = organizaciones.filter(org =>
                org.organizacion.toLowerCase().includes(searchTerm)
            );
            setOrganizaciones(filteredOrganizaciones);
        }
    };

    const handleSearchClick = () => {
        const filteredOrganizaciones = organizaciones.filter(org =>
            org.organizacion.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setOrganizaciones(filteredOrganizaciones); // Actualiza el estado con las organizaciones filtradas
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setFilteredInfo({ ...filteredInfo, [dataIndex]: selectedKeys });
    };

    const handleReset = (clearFilters, dataIndex, confirm) => {
        clearFilters();
        setFilteredInfo({ ...filteredInfo, [dataIndex]: null });
        confirm();
    };

    const clearAllFilters = async () => {
        setFilteredInfo({});
        setSearchTerm('');

        try {
            const { status, data } = await getOrganizaciones();
            if (status === 200) {
                if (oid_proyecto_busqueda) {
                    const filteredData = data.filter(org => org.oid_proyecto === parseInt(oid_proyecto_busqueda));
                    setOrganizaciones(filteredData || []);
                } else {
                    setOrganizaciones(data || []);
                }
            } else {
                setOrganizaciones([]);
            }
        } catch (error) {
            console.error('Error fetching organizations:', error);
            setOrganizaciones([]);
        }
    };


    const noDataConfig = {
        emptyText: (
            <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <InboxOutlined style={{ fontSize: '2rem' }} />
                <span>Sin resultados</span>
            </span>
        ),
    };


    const calcularTotalParticipantes = (n_hombres, n_mujeres) => {
        return n_hombres + n_mujeres;
    };


    const getColumnSearchProps = (dataIndex) => {
        let searchInput = React.createRef();

        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        id={`${dataIndex}-search-input`}
                        placeholder={`Buscar ${dataIndex}`}
                        value={selectedKeys ? selectedKeys[0] : ''}

                        ref={node => {
                            searchInput = node;
                        }}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Buscar
                        </Button>
                        <Button
                            onClick={() => handleReset(clearFilters, dataIndex, confirm)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Resetear
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) =>
                record[dataIndex]
                    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                    : '',
            onFilterDropdownOpenChange: visible => {
                if (visible) {
                    setTimeout(() => {
                        searchInput?.select();
                    }, 100);
                }
            },
        };
    };
      //Funcion para reemplazar espacios por guiones bajos
      function replaceSpacesWithUnderscores(text) {
        if (typeof text !== 'string') {
            return '';
        }
        return text.replace(/ /g, '_');
    }
    function getFirstMunicipio(municipios) {
        if (typeof municipios !== 'string') {
            return ''; // o manejar el caso cuando no es una cadena
        }

        const firstCommaIndex = municipios.indexOf(',');
        if (firstCommaIndex === -1) {
            return municipios; // No hay comas, devolver la cadena completa
        }
        return municipios.substring(0, firstCommaIndex).trim();
    }
const handleCancelSoporte = () => {
        setIsModalVisibleSoportes(false);
        form.resetFields();
        //setEditMode(false);
        setCurrentOrg(null);
    };
 //Funcion para mostrar modal
 const showModalSoportes = (title, orgId, supportId, initialOrg) => {
    setModalTitle(title); // Titulo del Modal
    setOrgId(orgId); // ID de la organizacion
    setSupportId(supportId); // Tipo de soporte 1: Mer 2:Organizacion 3: Plan de Accion
    setInitialsOrg(initialOrg); // Inicial de la organizacion + Ruta
    setIsModalVisibleSoportes(true); // Muestra el modal
};
    const renderBotonesSoporte = (record) => {
        const tieneSoporteMer = record.mer > 0; // Verifica si tiene soporte MER
        const tieneSoporteRH = record.rh > 0;
        const tieneSoportePA = record.pa > 0;
        const departamento = replaceSpacesWithUnderscores(getFirstMunicipio(record?.departamentos));
        const municipio = replaceSpacesWithUnderscores(getFirstMunicipio(record?.municipios));
        const organizacionID = record?.oid;
        const ruta = departamento && municipio && organizacionID ? `${departamento}/${municipio}/Cod_${organizacionID}/` : '';


        return (
            <Space size="middle">
                <Tooltip placement="topLeft" title={"MER"}>
                    <Button
                        onClick={() => showModalSoportes("MER", record.oid, 1, `${ruta}Mer1/`)}
                        icon={<FontAwesomeIcon icon={faFile} />}
                        style={{ color: tieneSoporteMer ? 'green' : 'red' }}
                    />
                </Tooltip>
                <Tooltip placement="topLeft" title={"Reporte Homologación"}>
                    <Button
                        onClick={() => showModalSoportes("Reporte Homologación", record.oid, 2, `${ruta}Reporte_Homologacion/`)}
                        icon={<FontAwesomeIcon icon={faFile} />}
                        style={{ color: tieneSoporteRH ? 'green' : 'red' }}
                    />
                </Tooltip>
                <Tooltip placement="topLeft" title={"Plan de Acción"}>
                    <Button
                        onClick={() => showModalSoportes("Plan de Acción", record.oid, 3, `${ruta}Plan_de_Accion/`)}
                        icon={<FontAwesomeIcon icon={faFile} />}
                        style={{ color: tieneSoportePA ? 'green' : 'red' }}
                    />
                </Tooltip>
            </Space>
        );
    };
    const columns = [
        {
            title: 'ID', dataIndex: 'oid', key: 'oid', width: 50,
            align: 'center', fixed: 'left',
        },...(permissions.priv_update === 'Y'
            ? [
                {
                    title: 'Acción',
                    dataIndex: 'acción',
                    align: 'center',
                    render: (_, record) => (
                        <>
                            <Button onClick={() => showEditModal(record)} className="btn btn-primary py-0">
                                Editar
                            </Button>
                        </>
                    ),
                },
            ]
            : [] // Si no hay permiso, no agregamos esta columna
        ),
        {
            title: 'Departamentos',
            dataIndex: 'departamentos',
            key: 'departamentos',
            sorter: {
                compare: (a, b) => a.departamentos.localeCompare(b.departamentos),
                multiple: 3,
            },
            filterSearch: true,
            filters: departamentosFilters,
            filteredValue: filteredInfo.departamentos || null,
            onFilter: (value, record) => record.departamentos.startsWith(value),
            width: 180,
        },
        { title: 'Municipios', dataIndex: 'municipios', key: 'municipios', width: 150 },
        {
            title: 'NIT',
            dataIndex: 'Nit',
            key: 'Nit',
            ...getColumnSearchProps('Nit'),
            width: 150,
            filterSearch: true,
            filteredValue: filteredInfo.Nit || null,
            fixed: 'left',
        },
        {
            title: 'Nombre de Organización',
            dataIndex: 'organizacion',
            key: 'organizacion',
            sorter: {
                compare: (a, b) => a.organizacion.localeCompare(b.organizacion),
                multiple: 3,
            },
            width: 320,
            filteredValue: filteredInfo.organizacion || null,
            ...getColumnSearchProps('organizacion'),
        },
        { title: 'Sigla', dataIndex: 'sigla', key: 'sigla', width: 220, fixed: 'left', },
        {
            title: 'Tipo de Organización',
            dataIndex: 'tipo_organizacion',
            key: 'tipo_organizacion',
            width: 180,
            filters: tiposOrganizacionFilters,
            filteredValue: filteredInfo.tipo_organizacion || null,
            onFilter: (value, record) => {
                if (record.children) {
                    return record.children.some(child => child.tipo_organizacion === value);
                }
                return record.tipo_organizacion === value;
            },
        },
        { title: 'Hombres', dataIndex: 'n_hombres', key: 'n_hombres', width: 100, align: 'center' },
        { title: 'Mujeres', dataIndex: 'n_mujeres', key: 'n_mujeres', width: 100, align: 'center' },
        { title: 'Total Participantes', dataIndex: 'total_participantes', key: 'total_participantes', width: 170, align: 'center' },
        {
            title: 'Organización de Mujeres',
            dataIndex: 'org_mujeres',
            key: 'org_mujeres',
            width: 200,
            filterMode: 'tree',
            align: 'center',
            filteredValue: filteredInfo.org_mujeres || null,
            filters: OrganizacionMujeresFilters,
            onFilter: (value, record) => {
                if (record.children) {
                    return record.children.some(child => child.org_mujeres === value);
                }
                return record.org_mujeres === value;
            },
        },
        {
            title: 'Proyectos',
            dataIndex: 'proyecto_detalle',
            key: 'proyecto_detalle',
            filterMode: 'tree',
            filterSearch: true,
            filters: ProyectosFilters,
            onFilter: (value, record) => {
                if (record.children) {
                    return record.children.some(child => child.proyecto_detalle === value);
                }
                return record.proyecto_detalle === value;
            },
            filteredValue: filteredInfo.proyecto_detalle || null,
            width: 200,
        },
        {
            title: '¿Se ha aplicado MER Inicial?',
            dataIndex: 'tienemer',
            key: 'tienemer',
            width: 200,
            align: 'center',
        },
        {
            title: '% MER Inicial',
            dataIndex: 'porcentaje',
            key: 'porcentaje',
            width: 150,
            align: 'center',
        }, {
            title: 'Soportes',
            dataIndex: 'soportes',
            align: 'center',
            render: (_, record) => renderBotonesSoporte(record),
        },
        
    ];

    const flattenTree = (nodes) => {
        let flatList = [];
        const traverse = (node) => {
            flatList.push(node);
            if (node.children) {
                node.children.forEach(child => traverse(child));
            }
        };
        nodes.forEach(node => traverse(node));
        return flatList;
    };



    const treeColumns = [
        {
            title: 'ID',
            dataIndex: 'oid',
            key: 'oid',
            width: 50,
            fixed: 'left',
            sorter: {
                compare: (a, b) => a.oid - b.oid,
                multiple: 1,
            },
        },
        {
            title: 'Proyecto',
            dataIndex: 'proyecto_detalle',
            key: 'proyecto_detalle',
            width: 170,
            fixed: 'left',
            filteredValue: filteredInfo.proyecto_detalle || null,
            onFilter: (value, record) => {
                const flatList = flattenTree([record]);
                const filtered = flatList.some(node => node.proyecto_detalle === value);
                return filtered;
            },
        },
        {
            title: 'Departamentos',
            dataIndex: 'departamentos',
            key: 'departamentos',
            width: 170,
            filters: departamentosFilters,
            filteredValue: filteredInfo.departamentos || null,
            onFilter: (value, record) => {
                const flatList = flattenTree([record]);
                const filtered = flatList.some(node => node.departamentos && node.departamentos.startsWith(value));
                return filtered;
            },
            sorter: {
                compare: (a, b) => (a.departamentos?.localeCompare(b.departamentos) || 0),
                multiple: 3,
            },
        },
        {
            title: 'Municipios',
            dataIndex: 'municipios',
            key: 'municipios',
            width: 150,
            sorter: {
                compare: (a, b) => {
                    if (a.municipios && b.municipios) {
                        return a.municipios.localeCompare(b.municipios);
                    }
                    return 0;
                },
                multiple: 4,
            },
        },

        {
            title: 'NIT',
            dataIndex: 'Nit',
            key: 'Nit',
            width: 150,
            filters: OrganizacionNitFilters,
            filteredValue: filteredInfo.Nit || null,
            filterSearch: true,
            onFilter: (value, record) => {
                const flatList = flattenTree([record]);
                const filtered = flatList.some(node => node.Nit && node.Nit.startsWith(value));
                return filtered;
            },

        },
        {
            title: 'Nombre de Organización',
            dataIndex: 'organizacion',
            key: 'organizacion',
            width: 250,
            fixed: 'left',
            filters: OrganizacionesNameFilters,
            filteredValue: filteredInfo.organizacion || null,
            filterSearch: true,
            onFilter: (value, record) => {
                const flatList = flattenTree([record]);
                const filtered = flatList.some(node => node.organizacion && node.organizacion.startsWith(value));
                return filtered;
            },

        },
        {
            title: 'Sigla',
            dataIndex: 'sigla',
            key: 'sigla',
            width: 120,
        },
        {
            title: 'Tipo de Organización',
            dataIndex: 'tipo_organizacion',
            key: 'tipo_organizacion',
            width: 180,
            filters: tiposOrganizacionFilters,
            filteredValue: filteredInfo.tipo_organizacion || null,
            onFilter: (value, record) => {

                const flatList = flattenTree([record]);

                const filtered = flatList.some(node => node.tipo_organizacion === value);

                return filtered;
            },
        },

        {
            title: 'Hombres',
            dataIndex: 'n_hombres',
            key: 'n_hombres',
            width: 100,
            align: 'center',
            sorter: {
                compare: (a, b) => a.n_hombres - b.n_hombres,
                multiple: 5,
            },
        },
        {
            title: 'Mujeres',
            dataIndex: 'n_mujeres',
            key: 'n_mujeres',
            width: 100,
            align: 'center',
            sorter: {
                compare: (a, b) => a.n_mujeres - b.n_mujeres,
                multiple: 6,
            },
        },
        {
            title: 'Total Participantes',
            dataIndex: 'total_participantes',
            key: 'total_participantes',
            width: 150,
            align: 'center',
            sorter: {
                compare: (a, b) => a.total_participantes - b.total_participantes,
                multiple: 7,
            },
        },
        {
            title: 'Organización de Mujeres',
            dataIndex: 'org_mujeres',
            key: 'org_mujeres',
            width: 180,
            align: 'center',
            filters: OrganizacionMujeresFilters,
            filteredValue: filteredInfo.org_mujeres || null,
            onFilter: (value, record) => {



                const flatList = flattenTree([record]);


                const filtered = flatList.some(node => node.org_mujeres === value);


                return filtered;
            },
        },
        {
            title: 'Acción',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            align: 'center',
            width: 100,
            render: (_, record) => (
                !record.children && (
                    <Button onClick={() => showEditModal(record)} className="btn btn-primary py-0">Editar</Button>
                )
            ),
        },
    ];




    const renderErrorMessage = (status) => {
        if (status === 404) {
            return (
                <Alert type="warning" message="Advertencia" description="Lo sentimos, no se encuentran organizaciones disponibles para mostrar." showIcon icon={<FontAwesomeIcon icon={faExclamationTriangle} />} />
            );
        } else if (status === 500) {
            return (
                <Alert type="error" message="Error" description="Lo sentimos, ha ocurrido un error al obtener las organizaciones. Por favor, inténtelo de nuevo más tarde o contacte a soporte@comunix.co" showIcon icon={<FontAwesomeIcon icon={faExclamationCircle} />} />
            );
        }
    };

    const exportToExcel = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = 'Organizaciones_UTF_COL_160' + fileExtension;
        // Mapear y seleccionar solo las columnas que quieres exportar
        const dataToExport = organizaciones.map(org => ({
            OID: org.oid,
            Nit: org.Nit,
            Organización: org.organizacion,
            Sigla: org.sigla,
            'Tipo de Organización': org.tipo_organizacion,
            'Número de Hombres': org.n_hombres,
            'Número de Mujeres': org.n_mujeres,
            'Total Participantes': org.total_participantes,
            'Organización de Mujeres': org.org_mujeres,
            'Proyecto Detalle': org.proyecto_detalle,
            Municipios: org.municipios,
            Departamentos: org.departamentos,
        }));

        // Convertir los datos seleccionados a formato Excel
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = { Sheets: { 'organizaciones': ws }, SheetNames: ['organizaciones'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Crear Blob y descargar
        const blob = new Blob([excelBuffer], { type: fileType });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        downloadLink.click();
    };


    const handleOrganizacionChange = (value) => {
        setOrgOrganizaciones(value); // Actualizar el estado con el oid seleccionado
    };

    const handleOrgMujeresChange = (e) => {
        setOrgMujeres(e.target.value === 'Si' ? 1 : 2); // Actualizar el estado con 1 o 2
    };


    return (
        <div className="container">
            {loading ? (
                <div className="text-center"><Spin size="large" /></div>
            ) : (


                <React.Fragment>
                    {status === 404 || status === 500 ? (
                        renderErrorMessage(status)
                    ) : (
                        <React.Fragment>

                            <div className="row mb-2 d-flex align-items-center">
                                <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                                    <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                                        <p>PROYECTO UTF <br /> COL 160 COL</p>
                                    </div>
                                    <div className='objeto' style={{ flexBasis: '75%', flexGrow: 0 }}>
                                        <p>Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET</p>

                                    </div>


                                </div>


                                <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">

                                    <h2 className="text-center mb-2">Listado de Organizaciones</h2>
                                    <div className="row mb-4">
                                        <div className="col-md-12">
                                            <div className="input-group shadow-sm">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Buscar organizaciones..."
                                                    value={searchTerm} // Vincula el estado de búsqueda al input
                                                    onChange={handleSearchInputChange} // Maneja el cambio en el input
                                                />
                                                <button
                                                    className="btn btn-primary"
                                                    type="button"
                                                    onClick={handleSearchClick} // Maneja el clic en el botón de búsqueda
                                                >
                                                    <SearchOutlined /> {/* Incluye el icono aquí */}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="d-flex justify-content-end mt-2">


                                                <Tooltip title="Vista de Tabla">
                                                    <Button
                                                        type="primary"
                                                        className="btn btn-primary me-2"
                                                        size="large"
                                                        onClick={handleClickTable}
                                                        disabled={viewMode === 'table'}
                                                    >
                                                        <FaList />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Vista de Árbol">
                                                    <Button
                                                        type="primary"
                                                        className="btn btn-primary me-2"
                                                        size="large"
                                                        onClick={handleClickTree}
                                                        disabled={viewMode === 'tree'}
                                                    >
                                                        <FaTable />
                                                    </Button>
                                                </Tooltip>

                                                {permissionInsert && (
                                                    <Button
                                                        type="danger"
                                                        className="btn btn-primary me-2"
                                                        onClick={showModal}
                                                        size="large"
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Nueva
                                                    </Button>

                                                )}
                                                <Button
                                                    type="danger"
                                                    className="btn btn-primary me-2"
                                                    onClick={exportToExcel}
                                                    size="large"
                                                    icon={<DownloadOutlined />}
                                                >
                                                    Excel
                                                </Button>
                                                <Button
                                                    type="danger"
                                                    className="btn btn-primary"
                                                    onClick={clearAllFilters}
                                                    size="large"
                                                    icon={<ClearOutlined />}
                                                >
                                                    Limpiar
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {organizaciones.length > 0 ? (
                                viewMode === 'table' ? (
                                    <Table
                                        columns={columns}
                                        dataSource={organizaciones}
                                        rowKey='oid'
                                        bordered
                                        pagination={pagination}
                                        onChange={handleTableChange}
                                        sortDirections={['ascend', 'descend']}
                                        sortOrder={sortOrder}
                                        onSorterChange={setSortOrder}
                                        sortField={sortField}
                                        locale={noDataConfig}
                                        loading={loading}
                                        filteredInfo={filteredInfo}
                                        scroll={{ y: 500, x: 'max-content' }}
                                    />
                                ) : (
                                    <Table
                                        columns={treeColumns}
                                        dataSource={treeData}
                                        rowKey={record => record.key}
                                        bordered
                                        pagination={pagination}
                                        onChange={handleTableChange}
                                        sortDirections={['ascend', 'descend']}
                                        sortOrder={sortOrder}
                                        onSorterChange={setSortOrder}
                                        loading={loading}
                                        locale={noDataConfig}
                                        scroll={{ y: 500, x: 'max-content' }}


                                    />


                                )
                            ) : (
                                <p className="text-center">No hay organizaciones para mostrar.</p>
                            )}



                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            <Modal
                title={<h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>{editMode ? "Editar Organización" : "Nueva Organización"}</h2>}
                open={isModalVisible}
                onCancel={handleCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            // Asegúrate de que el campo `oid` esté presente en los valores antes de pasar al manejador
                            const finalValues = {
                                ...values,
                                oid: currentOrg?.oid, // Incluye el valor de OID aquí
                                oid_proyecto: currentOrg?.oid_proyecto
                            };
                            editMode ? handleEdit(finalValues) : handleAdd(finalValues);
                            form.resetFields(); // Resetea los campos del formulario después de guardar
                            setIsModalVisible(false); // Cierra el modal de edición o creación
                            setEditMode(false); // Desactiva el modo de edición
                            setCurrentOrg(null); // Reinicia el registro actual
                        })
                        .catch((errorInfo) => {
                            //console.log('Validate Failed:', errorInfo);
                        });
                }}
                okText="Guardar"
                cancelText="Cancelar"
                cancelButtonProps={{ className: 'btn btn-danger' }}
                width={800}
            >
                <Form form={form} layout="vertical" name="form_in_modal" onFinish={editMode ? handleEdit : handleAdd}>
                    <Row gutter={16}>
                        <Col span={12}>
                            {/* El campo OID ya no está aquí */}
                            <Form.Item
                                name="Nit"
                                label="Nit"
                                rules={[{ required: true, message: 'Por favor ingresa el Nit de la organización' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="sigla"
                                label="Sigla"
                                rules={[{ required: true, message: 'Por favor ingresa la sigla de la organización' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Row gutter={10}>
                                <Col span={12}>
                                    <Form.Item name="n_hombres" label="Número de hombres">
                                        <InputNumber
                                            min={0}
                                            onChange={(value) => {
                                                const n_hombres = value || 0;
                                                const n_mujeres = form.getFieldValue('n_mujeres') || 0;
                                                const totalParticipantes = calcularTotalParticipantes(n_hombres, n_mujeres);
                                                form.setFieldsValue({ total_participantes: totalParticipantes });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="n_mujeres" label="Número de mujeres">
                                        <InputNumber
                                            min={0}
                                            onChange={(value) => {
                                                const n_mujeres = value || 0;
                                                const n_hombres = form.getFieldValue('n_hombres') || 0;
                                                const totalParticipantes = calcularTotalParticipantes(n_hombres, n_mujeres);
                                                form.setFieldsValue({ total_participantes: totalParticipantes });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="tipo_organizacion"
                                label="Tipo de organización"
                                rules={[{ required: true, message: 'Por favor selecciona el tipo de organización' }]}
                            >
                                <Select placeholder="Selecciona el tipo de organización" onChange={handleOrganizacionChange}>
                                    {tiposOrganizacion.map((tipo) => (
                                        <Select.Option key={tipo.oid} value={tipo.oid}>
                                            {tipo.tipo_organizacion}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="org_mujeres"
                                label="Organización de mujeres"
                                rules={[{ required: true, message: 'Por favor selecciona una opción' }]}
                            >
                                <Radio.Group onChange={handleOrgMujeresChange}>
                                    <Radio value="Sí">Sí</Radio>
                                    <Radio value="No">No</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Col span={24}>
                                <Form.Item name="total_participantes" label="Total de participantes">
                                    <Input disabled />
                                </Form.Item>
                            </Col>

                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col span={24}>
                            <Form.Item
                                name="organizacion"
                                label="Organización"
                                rules={[{ required: true, message: 'Por favor ingresa el nombre de la organización' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="proyecto_detalle"
                                label="Detalle del proyecto"
                            >
                                {editMode ? (
                                    <Input disabled />
                                ) : (
                                    <Select
                                        showSearch
                                        placeholder="Selecciona o escribe el Proyecto"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {ProyectosFilters.map((proyecto) => (
                                            <Select.Option key={proyecto.value} value={proyecto.value}>
                                                {proyecto.text}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="departamentos"
                                label="Departamento"
                                rules={[{ required: true, message: 'Por favor selecciona un departamento' }]}
                            >
                                <Select onChange={handleDepartamentoChange} placeholder="Selecciona el departamento">
                                    {departamentos &&
                                        departamentos.map((depto) => (
                                            <Select.Option key={depto.oid_departamento} value={depto.oid_departamento}>
                                                {depto.nombre_departamento}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="municipios"
                                label="Municipios"
                                rules={[{ required: true, message: 'Por favor selecciona al menos un municipio' }]}
                            >
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona municipios"
                                    value={selectedMunicipios}
                                    onChange={handleMunicipiosChange}
                                    disabled={isMunicipiosDisabled}
                                >
                                    {selectedDepartamento &&
                                        municipiosPorDepartamento[selectedDepartamento]?.map((municipio) => (
                                            <Select.Option key={municipio.value} value={municipio.value}>
                                                {municipio.label}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <div>
                {oid_proyecto_busqueda && (
                    <Link to="/buscar-proyecto">
                        <Button
                            type="danger"
                            className="btn btn-primary me-2"
                            size="large"
                            icon={<LeftOutlined />}
                        >
                            Volver
                        </Button>

                    </Link>
                )}
            </div>
            <Modal
                title={<h3 style={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: '10px' }}>{modalTitle}</h3>}
                open={isModalVisibleSoportes}
                onCancel={handleCancelSoporte}
                footer={null}
                width={400}
            >
                <Form form={form} layout="vertical" name="form_in_modal">
                    <UploadImage orgId={modalOrgId} supportId={modalSupportId} initialOrg={modalInitialsOrg} onUploadSuccess={fetchOrganizaciones} />
                </Form>
            </Modal>
        </div >
    );
};

export default Organizaciones;

