import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';

const baseUrl = config.baseUrl;
const orgEndpointInsert = '/api/insertAcuerdosComerciales';


const api = axios.create({
    baseURL: config.baseUrl,
});

api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


export const createAcuerdoComercial = async (formData) => {
    try {
      const response = await axios.post(`${baseUrl}${orgEndpointInsert}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',  // Esto asegura que los archivos se manejen correctamente
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error al crear el acuerdo comercial:', error);
      throw error;
    }
  };
export default createAcuerdoComercial;
