import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/tree_descargar-archivos';

/**
 * Descarga un archivo desde el servidor basado en la ruta proporcionada por el nodo.
 * @param {Object} nodo - El nodo que contiene la información del archivo.
 * @param {string} nodo.path - La ruta del archivo en el servidor.
 * @param {string} nodo.name - El nombre del archivo para descargar.
 * @param {string} nodo.type - El tipo del nodo (archivo o carpeta).
 * @returns {Promise<boolean>} Retorna true si la descarga fue exitosa, false en caso contrario.
 */
const getTreeFile = async (nodo) => {
    const path = nodo.path && nodo.path.nombre_modulo ? nodo.path.nombre_modulo : nodo.path;
    const formData = new FormData();
    formData.append('carpeta', path); // Obtener la ruta del nodo

    try {
        const response = await axios.post(`${baseUrl}${endpoint}`, formData, {
            responseType: 'blob', // Indicar que la respuesta es un blob (archivo)
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        // Crear un objeto URL para el blob y crear un enlace de descarga
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        // Determinar el nombre del archivo descargado
        if (nodo.type === 'folder' || nodo.type === undefined) {
            if (nodo.path && nodo.path.nombre_modulo) {
                a.download = `${nodo.path.nombre_modulo}.zip`; // Si tiene valores, utiliza nodo.path.nombre_modulo
            } else {
                a.download = `${nodo.name}.zip`; // Si no tiene valores, utiliza nodo.name
            } // Nombre del archivo para descargar si es una carpeta o si el tipo es undefined
        } else {
            a.download = nodo.name; // Nombre del archivo para descargar si es un archivo
        }
        
        
        a.click();

        // Liberar el objeto URL creado
        window.URL.revokeObjectURL(url);

        // Verifica si la respuesta del servidor es exitosa
        if (response.status === 200) {
            return true; // Retorna true si la descarga fue exitosa
        } else {
            return false; // Retorna false si hubo un error en la descarga
        }
    } catch (error) {
        console.error('Error al descargar el archivo:', error);
        return false; // Retorna false si hubo un error en la descarga
    }
};

export { getTreeFile };
