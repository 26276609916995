import React, { useEffect, useState } from 'react';
import { Card, Collapse, Row, Col, Tooltip } from 'antd';
import { TeamOutlined, ProductOutlined, ManOutlined, WomanOutlined, HomeOutlined, BankOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import './MapsInicial.css';
import axios from 'axios';
import config from '../../config'; // Ajusta la ruta según tu estructura

const baseUrl = config.baseUrl;

const { Panel } = Collapse;

const DataCol = () => {
    const [datos, setDatos] = useState([]);
    const [totales, setTotales] = useState({
        totalDepartamentos: 0,
        totalMunicipios: 0,
        totalProyectos: 0,
        totalOrganizaciones: 0,
        totalHombres: 0,
        totalMujeres: 0,
        totalParticipantes: 0,
        municipiosPorDepto: {},
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/dataColombia`);
                const data = response.data.dataColombia[0];
                const dataOrg = response.data.dataOrg[0];
                const dataCollapse = response.data.dataCollapse;

                // Transforma los datos
                const municipiosPorDepto = dataCollapse.reduce((acc, item) => {
                    const listaMunicipios = item.listaMunicipios || "";
                    const listaOrganizaciones = item.listaOrganizaciones || "";
                    const listaProyectos = item.listaProyectos || "";

                    acc[item.departamento] = {
                        municipios: listaMunicipios
                            .split('</li><li>')
                            .map(m => m.replace(/<\/?li>/g, '').trim())
                            .filter(m => m.length > 0),
                        organizaciones: listaOrganizaciones
                            .split('</li><li>')
                            .map(org => org.replace(/<\/?li>/g, '').trim())
                            .filter(org => org.length > 0),
                        proyectos: listaProyectos
                            .split('</li><li>')
                            .map(proj => proj.replace(/<\/?li>/g, '').trim())
                            .filter(proj => proj.length > 0)
                    };

                    return acc;
                }, {});

                setDatos(data);
                setTotales({
                    totalDepartamentos: data.total_deptos || 0,
                    totalMunicipios: data.total_municipios || 0,
                    totalProyectos: data.total_proyectos || 0,
                    totalOrganizaciones: dataOrg.Total_Organizaciones || 0,
                    totalHombres: dataOrg.Total_Hombres || 0,
                    totalMujeres: dataOrg.Total_Mujeres || 0,
                    totalParticipantes: dataOrg.Total_Participantes || 0,
                    municipiosPorDepto: municipiosPorDepto,
                });
            } catch (error) {
                console.error('Error fetching map data:', error);
            }
        };

        fetchData();
    }, []);

    const cards = [
        { icon: <BankOutlined style={{ fontSize: '36px', color: '#338bc7' }} />, value: totales.totalDepartamentos, label: 'Departamentos', tooltip: 'Total Departamentos' },
        { icon: <HomeOutlined style={{ fontSize: '36px', color: '#338bc7' }} />, value: totales.totalMunicipios, label: 'Municipios', tooltip: 'Total Municipios' },
        { icon: <ProductOutlined style={{ fontSize: '36px', color: '#338bc7' }} />, value: totales.totalProyectos, label: 'Proyectos', tooltip: 'Total Proyectos' },
        { icon: <TeamOutlined style={{ fontSize: '36px', color: '#338bc7' }} />, value: totales.totalOrganizaciones, label: 'Organizaciones', tooltip: 'Total Organizaciones' },
        { icon: <ManOutlined style={{ fontSize: '36px', color: '#338bc7' }} />, value: totales.totalHombres, label: 'Hombres', tooltip: 'Total Hombres' },
        { icon: <WomanOutlined style={{ fontSize: '36px', color: '#338bc7' }} />, value: totales.totalMujeres, label: 'Mujeres', tooltip: 'Total Mujeres' },
        { icon: <TeamOutlined style={{ fontSize: '36px', color: '#338bc7' }} />, value: totales.totalParticipantes, label: 'Participantes', tooltip: 'Total Participantes' }
    ];

    // Divide el array en dos partes
    const firstRowCards = cards.slice(0, 3);
    const secondRowCards = cards.slice(3);

    // Genera los ítems para el Collapse usando items en lugar de children
    const collapseItems = Object.keys(totales.municipiosPorDepto).map((departamento, index) => ({
        key: index,
        label: departamento,
        children: (
            <div>
                <div>
                    <h4 className='titleCol'>Proyectos</h4>
                    <ul style={{ textAlign: 'left' }}>
                        {totales.municipiosPorDepto[departamento].proyectos.map((proyecto, idx) => (
                            <li key={idx}>{proyecto}</li>
                        ))}
                    </ul>
                </div>
                <div style={{ marginTop: '16px' }}>
                    <h4 className='titleCol'>Organizaciones</h4>
                    <ul style={{ textAlign: 'left' }}>
                        {totales.municipiosPorDepto[departamento].organizaciones.map((org, idx) => (
                            <li key={idx}>{org}</li>
                        ))}
                    </ul>
                </div>
                <div style={{ marginTop: '16px' }}>
                    <h4 className='titleCol'>Municipios</h4>
                    <ul style={{ textAlign: 'left' }}>
                        {totales.municipiosPorDepto[departamento].municipios.map((municipio, idx) => (
                            <li key={idx}>{municipio}</li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }));

    return (
        <div>
            <h3 className="title-custom text-center">
                UTF-COL-160-COL
            </h3>

            <Row gutter={16} className="mb-4 justify-content-center">
                {firstRowCards.map((item, index) => (
                    <Col span={8} key={index} className="mb-4">
                        <Tooltip title={item.tooltip}>
                            <Card className="text-center p-3 cardCol-hover">
                                {item.icon}
                                <div>
                                    <h2>{item.value}</h2>
                                    <p style={{ color: '#27558a' }}>{item.label}</p>
                                </div>
                            </Card>
                        </Tooltip>
                    </Col>
                ))}
            </Row>
            <Row gutter={16} className="mb-4 justify-content-center">
                {secondRowCards.map((item, index) => (
                    <Col span={6} key={index} className="mb-4">
                        <Tooltip title={item.tooltip}>
                            <Card className="text-center p-3 cardCol-hover">
                                {item.icon}
                                <div>
                                    <h2>{item.value}</h2>
                                    <p style={{ color: '#27558a' }}>{item.label}</p>
                                </div>
                            </Card>
                        </Tooltip>
                    </Col>
                ))}
            </Row>

            <Collapse
                style={{ background: '#4375af3b', textAlign: 'left' }}
                items={collapseItems}
                expandIconPosition="start" // Asegúrate de que el icono de expansión esté visible
            />
        </div>
    );
};

export default DataCol;
