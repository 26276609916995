import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/delete-tree-dir'; // Actualiza el endpoint aquí

const deleteDir = async (filePath) => {
  const formData = new FormData();
  formData.append('filePath', filePath);
  
  try {
    const response = await axios.post(`${baseUrl}${endpoint}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    // Verifica si la respuesta del servidor es exitosa
    if (response.data.status === 'success') {
      return true; // Retorna true si la eliminación fue exitosa
    } else {
      return false; // Retorna false si hubo un error en la eliminación
    }
  } catch (error) {
    console.error('Error al eliminar la carpeta:', error);
    return false; // Retorna false si hubo un error en la eliminación
  }
};

export { deleteDir };
