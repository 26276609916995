// src/services/poaService.ts
import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const orgEndpoint = '/api/query-poa';

export const fetchPoaData = async () => {
  try {
    const response = await axios.get(`${baseUrl}${orgEndpoint}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching POA data:', error);
    throw error;
  }
};

// Función para obtener datos POA con parámetros
export const fetchPoaDataWithParams = async (key) => {
  try {
    const response = await axios.get(`${baseUrl}${orgEndpoint}`, {
      params: { key }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching POA data with params:', error);
    throw error;
  }
};