// components/NavBodecom.jsx
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';
import { HomeOutlined, CloudSyncOutlined, TeamOutlined, FileTextOutlined, GlobalOutlined, AppstoreAddOutlined, FilePdfOutlined, LogoutOutlined, ApartmentOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/auth/authSlice';
import UserProfile from '../UserProfile/UserProfile';  // Importa el componente


import './NavBodecom.css';

const NavBodecom = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { applications } = useSelector(state => state.auth);

    const handleLogout = async () => {
        try {
            await dispatch(logout());
            navigate('/');
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };


    const menuItems = [
        { path: '/inicio', icon: <HomeOutlined />, label: 'Inicio', appName: 'inicio' },
        { path: '/buscar-proyecto', icon: <CloudSyncOutlined />, label: 'Actualización', appName: 'buscar-proyecto' },
        { path: '/es_proyectos', icon: <ApartmentOutlined />, label: 'Estructuración', appName: 'es_proyectos' },
        { path: '/organizaciones', icon: <TeamOutlined />, label: 'Socioempresarial', appName: 'organizaciones' },
        { path: '/participantes', icon: <TeamOutlined />, label: 'Participantes', appName: 'participantes' },
        { path: '/acuerdos-comerciales', icon: <TeamOutlined />, label: 'Comercial', appName: 'acuerdos-comerciales' },
        //{ path: '/mer', icon: <FileTextOutlined />, label: 'MER', appName: 'mer' },
        { path: '/poa', icon: <AppstoreAddOutlined />, label: 'POA', appName: 'poa' },
        { path: '/reportes', icon: <FilePdfOutlined />, label: 'Informes', appName: 'reportes' },
        { path: '/mapas', icon: <GlobalOutlined />, label: 'Mapas', appName: 'mapas' },
        { path: '/app-slider', icon: <GlobalOutlined />, label: 'Gestión slider', appName: 'app-slider' },
    ];

    const userApplications = applications.map(app => app.app_name);

    return (
        <Navbar bg="light" expand="lg" className="mb-4 full-width-navbar pl-5">
            <div className="navbar-container">
                <div className="container">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            {menuItems
                                .filter(item => userApplications.includes(item.appName))
                                .map(item => (
                                    <Nav.Link key={item.path} as={Link} to={item.path} className="nav-link">
                                        {item.icon} {item.label}
                                    </Nav.Link>
                                ))}
                            <Nav.Link onClick={handleLogout} className="nav-link">
                                <LogoutOutlined /> Salir
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </div>
        </Navbar>
    );
};

export default NavBodecom;
