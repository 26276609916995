import React, { useEffect, useState } from 'react';
import ReactFC from 'react-fusioncharts';


import FusionCharts from 'fusioncharts';
import FusionMaps from 'fusioncharts/fusioncharts.maps';


import DataCol from './dataCol';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import './MapsInicial.css';
import { getMapColombia } from '../../services/maps/getMapColombia';
import { getMapDepartment } from '../../services/maps/getMapDepartment';

// Importa todos los mapas de los departamentos
import ColombiaMap from 'fusioncharts/maps/fusioncharts.colombia';
import AntioquiaMap from 'fusioncharts/maps/fusioncharts.antioquia';
import AraucaMap from 'fusioncharts/maps/fusioncharts.arauca';
import AtlanticoMap from 'fusioncharts/maps/fusioncharts.atlantico';
import BolivarMap from 'fusioncharts/maps/fusioncharts.bolivar';
import BoyacaMap from 'fusioncharts/maps/fusioncharts.boyaca';
import CaldasMap from 'fusioncharts/maps/fusioncharts.caldas';
import CaquetaMap from 'fusioncharts/maps/fusioncharts.caqueta';
import CasanareMap from 'fusioncharts/maps/fusioncharts.casanare';
import CaucaMap from 'fusioncharts/maps/fusioncharts.cauca';
import CesarMap from 'fusioncharts/maps/fusioncharts.cesar';
import ChocoMap from 'fusioncharts/maps/fusioncharts.choco';
import CordobaMap from 'fusioncharts/maps/fusioncharts.cordoba';
import CundinamarcaMap from 'fusioncharts/maps/fusioncharts.cundinamarca';
import GuainiaMap from 'fusioncharts/maps/fusioncharts.guainia';
import GuaviareMap from 'fusioncharts/maps/fusioncharts.guaviare';
import GuajiraMap from 'fusioncharts/maps/fusioncharts.laguajira';
import HuilaMap from 'fusioncharts/maps/fusioncharts.huila';
import LaGuajiraMap from 'fusioncharts/maps/fusioncharts.laguajira';
import MagdalenaMap from 'fusioncharts/maps/fusioncharts.magdalena';
import MetaMap from 'fusioncharts/maps/fusioncharts.meta';
import NarinoMap from 'fusioncharts/maps/fusioncharts.narino';
import NorteDeSantanderMap from 'fusioncharts/maps/fusioncharts.nortedesantander';
import PutumayoMap from 'fusioncharts/maps/fusioncharts.putumayo';
import QuindioMap from 'fusioncharts/maps/fusioncharts.quindio';
import RisaraldaMap from 'fusioncharts/maps/fusioncharts.risaralda';
import SanAndresMap from 'fusioncharts/maps/fusioncharts.sanandresprovidenciaandsantacatalina';
import SantanderMap from 'fusioncharts/maps/fusioncharts.santander';
import SucreMap from 'fusioncharts/maps/fusioncharts.sucre';
import TolimaMap from 'fusioncharts/maps/fusioncharts.tolima';
import ValleDelCaucaMap from 'fusioncharts/maps/fusioncharts.valledelcauca';
import VaupesMap from 'fusioncharts/maps/fusioncharts.vaupes';
import VichadaMap from 'fusioncharts/maps/fusioncharts.vichada';
import AmazonasMap from 'fusioncharts/maps/fusioncharts.amazonas'; // Importa Amazonas


// Registra todos los mapas necesarios
ReactFC.fcRoot(FusionCharts, FusionMaps,
    ColombiaMap, AntioquiaMap, AraucaMap, AtlanticoMap, BolivarMap,
    BoyacaMap, CaldasMap, CaquetaMap, CasanareMap, CaucaMap,
    CesarMap, ChocoMap, CordobaMap, CundinamarcaMap, GuainiaMap,
    GuaviareMap, GuajiraMap, HuilaMap, LaGuajiraMap, MagdalenaMap,
    MetaMap, NarinoMap, NorteDeSantanderMap, PutumayoMap, QuindioMap,
    RisaraldaMap, SanAndresMap, SantanderMap, SucreMap, TolimaMap,
    ValleDelCaucaMap, VaupesMap, AmazonasMap, VichadaMap, FusionTheme
);

// Función para manejar el clic en el botón de volver


const ColombiaMapComponent = () => {
    const [mapData, setMapData] = useState(null);
    const [error, setError] = useState(null);
    const [currentMapType, setCurrentMapType] = useState('maps/colombia');
    const [currentDepartmentId, setCurrentDepartmentId] = useState(null);
    const [idMap, setIdMap] = useState({});
    const [nameMap, setNameMap] = useState({});
    const [showBackButton, setShowBackButton] = useState(false);
    const [titleDepto, settitleDepto] = useState();

    const handleBackButtonClick = () => {
        setCurrentMapType('maps/colombia');
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let result;
                if (currentMapType === 'maps/colombia') {

                    result = await getMapColombia();


                    const idMap = result.data.data.reduce((map, item) => {
                        map[item.id] = item.idCodInterno;
                        return map;
                    }, {});
                    setIdMap(idMap);

                    const nameMap = result.data.data.reduce((map, item) => {
                        map[item.id] = item.nameFusion;
                        return map;
                    }, {});
                    setNameMap(nameMap);

                    // Asegúrate de limpiar el estado del departamento actual
                    setCurrentDepartmentId(null);
                    setShowBackButton(false);
                    settitleDepto('');



                } else {
                    result = await getMapDepartment(currentDepartmentId);
                    setShowBackButton(true);

                }

                setMapData({
                    type: currentMapType,
                    renderAt: 'map',
                    width: '100%',
                    height: '600',
                    dataFormat: 'json',
                    dataSource: {
                        chart: {
                            caption: 'UTF COL 160 - COL',
                            subcaption: 'Proyectos por Departamento',
                            theme: 'fusion',
                            bgColor: '#dee3eb',
                            showLabels: '0',
                            showlegend: '0',
                            borderColor: '#042956',
                            borderThickness: '1',
                            hoverBorderColor: '#4275af',
                            hoverBorderThickness: '1',
                            toolTipBorderColor: '#042956',
                            toolTipBorderThickness: '1',
                            entityFillHoverColor: '#fbc02d',
                            entityBorderHoverColor: '#042956',
                            toolTipPosition: 'bottom',
                        },
                        data: result.data.data,
                    }
                });

            } catch (error) {

                setError('No se pudo cargar la información del mapa');
            }
        };

        fetchData();
    }, [currentMapType, currentDepartmentId]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    const handleEntityClick = (eventObj, dataObj) => {
        const departmentId = idMap[dataObj.id];
        const deptoName = nameMap[dataObj.id];

        if (departmentId && deptoName) {
            setCurrentDepartmentId(departmentId);
            const mapName = `maps/${deptoName}`;
            setCurrentMapType(mapName);
            settitleDepto(dataObj.label);
        }
    };

    return (
        <div className='container-fluid' style={{ display: 'flex', height: '100vh' }}>
            {showBackButton && (
                <button onClick={handleBackButtonClick} className="back-button">
                    Volver
                </button>
            )}

            <div className="row" style={{ flex: 1, display: 'flex' }}>
                <div className="col-md-5" style={{ overflowY: 'auto', maxHeight: '100vh' }}>
                    <DataCol />
                </div>
                <div className="col-md-7" style={{ position: 'sticky', top: 0, height: '100vh' }}>
                    <div id="map" style={{ height: '100%' }}>
                        <div className="header-container-depto">
                            {titleDepto && <h3 className="header-title">Departamento | {titleDepto}</h3>}
                        </div>

                        {mapData ? (
                            <ReactFC {...mapData} fcEvent-entityClick={handleEntityClick} />
                        ) : (
                            <p>Cargando...</p>
                        )}
                    </div>
                </div>
            </div>

            <div id="fixed-tooltip" style={{ position: 'absolute', pointerEvents: 'none' }}></div>
        </div>
    );

};

export default ColombiaMapComponent;
