import React, { useState, useEffect } from 'react';
import { Button, Popconfirm, message, Input, Table, Alert, Spin, Modal, Form, DatePicker, Tag, Row, Col, Upload, notification, Tooltip, Image } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { SearchOutlined, DownloadOutlined, ClearOutlined, PlusOutlined, InboxOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import config from '../../config';


//servicios
import getAppSlider from '../../services/appSlider/appSlider';
import insertAppSlider from '../../services/appSlider/insertAppSliderService';
import updateAppSlider from '../../services/appSlider/updateAppSliderService';
import deleteAppSlider from '../../services/appSlider/deleteAppSliderService';


const { TextArea } = Input;
const { Dragger } = Upload;

const baseUrl = config.baseUrl;

const AppSlider = () => {

    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(null);
    const [listaSlider, setListaSlider] = useState(null);

    const [pagination, setPagination] = useState({ pageSize: 30, current: 1 });
    const [sortOrder, setSortOrder] = useState({});
    const [sortField, setSortField] = useState('');
    const [filteredInfo, setFilteredInfo] = useState({});


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // Estado para controlar si es edición
    const [files, setFiles] = useState([]);  // Estado para almacenar los archivos


    const [editingImagen, setEditingImagen] = useState(null);

    const [form] = Form.useForm();

    const obtenerlistaSlider = async () => {
        try {
            const { status, data } = await getAppSlider();
            setStatus(status)
            setListaSlider(data)
        } catch (error) {
            setStatus(500);
            setListaSlider([]);
        } finally {
            setLoading(false);
        }


    }


    // useEffect para ajustar el formulario en modo edición o en nuevo
    useEffect(() => {
        obtenerlistaSlider();
        if (isEditing && editingImagen) {
            form.setFieldsValue({
                titulo: editingImagen.titulo,
                descripcion: editingImagen.descripcion
            });
            setFiles([
                {
                    uid: editingImagen.nombre_imagen, // Identificador único
                    name: editingImagen.nombre_imagen,
                    status: 'done',
                    url: `${baseUrl}${editingImagen.ruta}`,
                },
            ]);

        } else {
            form.resetFields()
            setFiles([]); // Limpiar los archivos si no se está editando
        }
    }, [isEditing, editingImagen]);


    //Funcion para mensajesdependiendo el estado de la solicitud al backend
    const renderErrorMessage = (status) => {
        if (status === 404) {
            return (
                <Alert type="warning" message="Advertencia" description="Lo sentimos, no se encuentran participantes disponibles para mostrar." showIcon icon={<FontAwesomeIcon icon={faExclamationTriangle} />} />
            );
        } else if (status === 500) {
            return (
                <Alert type="error" message="Error" description="Lo sentimos, ha ocurrido un error al obtener los participantes. Por favor, inténtelo de nuevo más tarde o contacte a soporte@comunix.co" showIcon icon={<FontAwesomeIcon icon={faExclamationCircle} />} />
            );
        }
    };



    const handleCancel = () => {
        if (form.isFieldsTouched()) {
            Modal.confirm({
                title: '¿Estás seguro?',
                content: 'Si cierras el modal, se perderán los datos no guardados.',
                okText: 'Sí, cerrar',
                cancelText: 'Cancelar',
                onOk() {
                    setIsModalVisible(false);
                    form.resetFields();
                }
            });
        } else {
            form.resetFields();
            setIsModalVisible(false);

        }
    };


    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Solo puede cargar archivos en formato PNG o JPG.');
        }
        return isJpgOrPng || Upload.LIST_IGNORE;
    };

    const showModal = () => {
        form.resetFields(); // Resetear los campos del formulario
        setIsEditing(false);
        setFiles([])
        setIsModalVisible(true);

    };

    // Función para manejar el cambio de archivos
    const handleFileChange = ({ fileList }) => {
        setFiles(fileList);  // Actualiza el estado con los archivos seleccionados
    };



    const handleSubmit = async (values) => {
        try {
            const formData = new FormData(); // Usamos FormData para enviar archivos

            // Formatear la fecha_acuerdo a 'YYYY-MM-DD'
            const formattedValues = {
                ...values
            };

            // Añadir otros valores del formulario a FormData
            for (let key in formattedValues) {
                if (Array.isArray(formattedValues[key])) {
                    // Añadir cada elemento del array como un campo separado en FormData
                    formattedValues[key].forEach(item => {
                        formData.append(`${key}[]`, item); // No conviertas a número aquí si no es necesario
                    });
                } else {
                    formData.append(key, formattedValues[key]);
                }
            }

            // Añadir los archivos a FormData
    
            // Procesar los archivos
            files.forEach(file => {
                if (file.originFileObj) {
                    // Archivos nuevos
                    formData.append('soportes', file.originFileObj);
                } else {

                }
            });

            let response;
            if (isEditing && editingImagen) {
                // Añadir el ID del acuerdo comercial al FormData
                formData.append('oid', editingImagen.oid);

                // Convertir existingFilesArray a un JSON string y agregarlo a formData
                formData.append('fileExistente', editingImagen.nombre_imagen);
                // Modo de edición - actualizar una imagen
                response = await updateAppSlider(formData); // Asegúrate de que updateAcuerdoComercial maneje FormData
            } else {
                // Modo de creación - insertar nuevo acuerdo comercial
                response = await insertAppSlider(formData); // Asegúrate de que createAcuerdoComercial maneje FormData
            }


            const successMessage = isEditing
                ? "Imagen actualizada correctamente"
                : "Imagen guardada correctamente";

            notification.success({
                message: 'Éxito',
                description: successMessage,
            });

            obtenerlistaSlider();
            setIsModalVisible(false);
            form.resetFields(); // Limpiar el formulario después de un envío exitoso
            setFiles([]); // Limpiar los archivos después de enviar el formulario

        } catch (err) {
            notification.error({
                message: 'Error',
                description: 'Error guardar imagen. Inténtelo de nuevo.',
            });
        }
    };

    const handleConfirm = () => {
        form.validateFields().then(values => {
            handleSubmit(values);
        }).catch(info => {
        });
    };
    const handleCancelConfirm = () => {
        notification.info({
            message: 'Info',
            description: 'El envío del formulario ha sido cancelado',
        });
    };


       //Función para limpiar filtro de busqueda
       const clearAllFilters = async () => {
        setFilteredInfo({});
        obtenerlistaSlider()
    };

    const handleEdit = async (record) => {
        try {
            setEditingImagen(record);
            setIsModalVisible(true);
            setIsEditing(true);

        } catch (error) {
        }
    };

    const handleDelete = async (record) => {
      const response =   await deleteAppSlider(record.oid)
    if(response.status == 200){

        notification.success({
            message: 'Éxito',
            description: "Eliminación exitosa",
        });

        obtenerlistaSlider();
    }else{

        notification.error({
            message: 'Error',
            description: "Ha ocurrido un errro al eliminar el registro",
        });

    }
    };


    const columns = [
        {
            title: "Acciones",
            dataIndex: "actions",
            key: "actions",
            width: 100,
            filteredValue: null,
            render: (text, record) => (
                <div>
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        size="small"
                        onClick={() => handleEdit(record)}
                        style={{ marginRight: 8 }} // Añadir margen entre botones
                    />
                    <Popconfirm
                        title="¿Estás seguro de que deseas eliminar esta imagen?"
                        onConfirm={() => handleDelete(record)}
                        okText="Sí"
                        cancelText="No"
                    >
                        <Button
                            type="danger"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            size="small"
                        />
                    </Popconfirm>
                </div>
            ),
        },
        {
            title: 'Imagen',
            dataIndex: 'url', // Usa el campo 'url' que contiene la parte restante de la URL de la imagen
            key: 'imagen',
            width: 50,
            align: 'center',
            filteredValue: null,
            render: (text, record) => (
                <Image
                    width={100}
                    src={`${baseUrl}${record.ruta}`}
                    alt={record.titulo}
                />
            ),
        },
        {
            title: 'Título',
            dataIndex: 'titulo',
            key: 'titulo',
            width: 150,
            align: 'center',
            filteredValue: null,
        },
        {
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            width: 150,
            align: 'center',
            filteredValue: null,
        },
        {
            title: 'Nombre de la imagen',
            dataIndex: 'nombre_imagen',
            key: 'nombre_imagen',
            width: 150,
            align: 'center',
            filteredValue: null,
        },
    ];

    const handleTableChange = (pagination, filters, sorter, extra) => {
        setPagination(pagination);
        setSortOrder(sorter.order);
        setSortField(sorter.field);
        setFilteredInfo(filters);
    };

    //Configuración d elas columnas d ela tabla
    const noDataConfig = {
        emptyText: (
            <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <InboxOutlined style={{ fontSize: '2rem' }} />
                <span>Sin resultados</span>
            </span>
        ),
    };

    const validateFile = () => {
        if (files.length === 0) {
            return Promise.reject('Por favor, carga una imagen!');
        }
        return Promise.resolve();
    };
    if (loading) {
        return <div className="text-center"><Spin size="large" /></div>;
    }

    return (
        <div className="container">
            <React.Fragment>
                {status === 404 || status === 500 ? (
                    renderErrorMessage(status)
                ) : (
                    <React.Fragment>
                        <div className="row mb-2 d-flex align-items-center">
                            <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                                <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                                    <p>PROYECTO UTF <br /> COL 160 COL</p>
                                </div>
                                <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                                    <p>Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET</p>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                                <h2 className="text-center mb-2">Listado de imagenes</h2>
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <div className="input-group shadow-sm">
                                            <Button
                                                type="danger"
                                                className="btn btn-primary me-2"
                                                size="large"
                                                onClick={showModal}
                                                icon={<PlusOutlined />}
                                            >
                                                Nueva
                                            </Button>
                                            <Button
                                                type="danger"
                                                className="btn btn-primary me-2"
                                                size="large"
                                                icon={<DownloadOutlined />}
                                            >
                                                Excel
                                            </Button>


                                            <Button
                                                type="danger"
                                                className="btn btn-primary"
                                                size="large"
                                                onClick={clearAllFilters}
                                                icon={<ClearOutlined />}
                                            >
                                                Limpiar
                                            </Button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {listaSlider.length > 0 ? (
                            <Table
                                columns={columns}
                                dataSource={listaSlider}
                                rowKey='oid'
                                bordered
                                pagination={pagination}
                                onChange={handleTableChange}
                                sortDirections={['ascend', 'descend']}
                                sortOrder={sortOrder}
                                onSorterChange={setSortOrder}
                                sortField={sortField}
                                locale={noDataConfig}
                                loading={loading}
                                filteredInfo={filteredInfo}
                                scroll={{ y: 500, x: 'max-content' }}
                            />
                        ) : (
                            <p className="text-center">No hay acuerdos comerciales para mostrar.</p>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>

            <Modal
                title={<h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>{isEditing ? "Editar imagen" : "Registrar imagen"}</h2>}
                style={{ top: 20 }}
                width={1000}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancelar
                    </Button>,
                    <Popconfirm
                        key="confirm"
                        title="¿Estás seguro de enviar el formulario?"
                        onConfirm={handleConfirm}
                        onCancel={handleCancelConfirm}
                        okText="Sí"
                        cancelText="No"
                    >
                        <Button type="primary">
                            {isEditing ? 'Actualizar' : 'Registrar'}
                        </Button>
                    </Popconfirm>,
                ]}
            >
                <Form form={form} layout="vertical">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Titulo" name="titulo" rules={[{ required: true, message: 'Por favor diligencia el titulo!' }]}>

                                <TextArea placeholder="Escribe Titulo" autoSize />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Descripción" name="descripcion" rules={[{ required: true, message: 'Por favor diligencia udescripción!' }]}>

                                <TextArea placeholder="Escribe descripción" autoSize />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Soporte"
                                name="soporte"
                                rules={[
                                    {
                                        validator: validateFile,
                                    }
                                ]}
                            >
                                <Dragger
                                    accept=".jpg,.jpeg,.png"
                                    beforeUpload={beforeUpload}
                                    fileList={files}
                                    onChange={handleFileChange}
                                    showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        Haga clic o arrastre los archivos a esta área para cargarlos.
                                    </p>
                                    <p className="ant-upload-hint">
                                        Soporte para una carga única o masiva.
                                    </p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </div>
    );
};

export default AppSlider;
