import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, message, Input, Table, Alert, Spin, Modal, Form, DatePicker, Tag, Select, Row, Col, Radio, Upload, notification, Tooltip } from 'antd';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../../config';
import { faExclamationTriangle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { SearchOutlined, DownloadOutlined, ClearOutlined, EditOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import moment from "moment"; // Importa moment.js



//Estilos css personalizados
import './AcuerdosComerciales.css'
//Importamos libreria para la exportación de excel
import ExcelJS from 'exceljs';

//Servicios
import getProyectosxmunicipio from '../../services/fetch_projects/proyectosxMunicipio';
import getOrganizacionesxproyectos from '../../services/organization/organizacionesxProyectoService';
import getParticipantesxproyectos from '../../services/participant/participantesxProyectosService';
import getAcuerdosComerciales from '../../services/acuerdosComerciales/acuerdoComercialService';
import { createAcuerdoComercial } from '../../services/acuerdosComerciales/insertAcuerdoComercialServide';
import updateAcuerdoComercial from '../../services/acuerdosComerciales/updateAcuerdoComercialService';
import getArchivosAcuerdosComerciales from '../../services/acuerdosComerciales/archivosAcuerdoComercialService';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';


const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;

const optionsTipoactividad = [
    { label: 'Asociativo', value: 'Asociativo' },
    { label: 'Colectivo', value: 'Colectivo' },
    { label: 'Individual', value: 'Individual', title: 'Orange' },
];

const baseUrl = config.baseUrl;

const AcuerdosComerciales = () => {

    const [acuerdosComerciales, setAcuerdosComerciales] = useState([]);
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [viewMode] = useState('table'); // Estado para manejar el modo de vista
    const [pagination, setPagination] = useState({ pageSize: 30, current: 1 });
    const [sortOrder, setSortOrder] = useState({});
    const [sortField, setSortField] = useState('');
    const [filteredInfo, setFilteredInfo] = useState({});
    const [searchTerm, setSearchTerm] = useState(''); // Agrega este estado
    const location = useLocation();
    const { nombre_acuerdo } = location.state || {};


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // Estado para controlar si es edición
    const [editingUser, setEditingUser] = useState(null);

    const [form] = Form.useForm();

    const [departamentos, setDepartamentos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [proyectos, setProyectos] = useState([]);
    const [proyectosxmunicipio, setProyectosxmunicipio] = useState([]);
    const [organizacionesxMunicipios, setOrganizacionesxMunicipios] = useState([]);
    const [participantesxproyecto, setParticipantesxproyecto] = useState([]);


    const [selectedDepartamento, setSelectedDepartamento] = useState(null);
    const [selectedMunicipios, setSelectedMunicipios] = useState([]);
    const [selectedProyectos, setSelectedProyectos] = useState([]);
    const [selectedOrganizaciones, setSelectedOrganizaciones] = useState([]);
    const [selectedParticipantes, setSelectedParticipantes] = useState([]);


    const [isMunicipiosDisabled, setIsMunicipiosDisabled] = useState(true);
    const [isProyectosDisabled, setIsProyectosDisabled] = useState(true);
    const [isOrganizacionesDisabled, setIsOrganizacionesDisabled] = useState(true);
    const [isTipoAcuerdoDisabled, setIsTipoAcuerdoDisabled] = useState(true);
    const [isParticipantesDisabled, setIsParticipantesDisabled] = useState(true);


    const [departamentosFilters, setDepartamentosFilters] = useState([]); //Estado para filtrar por departamentos
    const [tipoAcuerdoFilters, setTipoacuerdoFilters] = useState([]); //Estado para filtrar por tipo de acuerdo
    const [tipoAlcanceFilters, setTipoAlcanceFilters] = useState([]); //Estado para filtrar por tipo de alcance


    const [tipoAcuerdo, setTipoAcuerdo] = useState('');
    const [tipoAlcance] = useState('');
    const [files, setFiles] = useState([]);  // Estado para almacenar los archivos
    const [formValues, setFormValues] = useState({});

    //Control de Permisos
    const appName = 'acuerdos-comerciales'; // El nombre de la aplicación actual
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionDelete = permissions.priv_delete === 'Y';
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionInsert = permissions.priv_insert === 'Y';
    const permissionExport = permissions.priv_export == 'Y'



    //Filtrar los departamentos seleccionados

    const handleDepartamentoChange = (value) => {
        setSelectedDepartamento(value);  // Actualiza el departamento seleccionado
        setSelectedMunicipios([]);
        form.setFieldsValue({ municipios: [] });
        setIsMunicipiosDisabled(false);
    };


    //Filtrar los municipios seleccionados
    const handleMunicipiosChange = (value) => {
        // Verificar si `value` es nulo, indefinido, una cadena vacía o un array vacío
        if (!value || value.length === 0 || (typeof value === 'string' && value.trim() === '')) {
            setSelectedProyectos([]); // Limpia los proyectos seleccionados
            form.setFieldsValue({ oid_proyecto: [] }); // Limpia el campo del formulario
            setIsProyectosDisabled(true); // Habilita el campo de proyectos

            setTipoAcuerdo(''); // Limpia los tipo acuerdo
            form.setFieldsValue({ tipo_acuerdo: '' }); // Limpia el campo del formulario
            form.setFieldsValue({ organizaciones: '' }); // Limpia el campo del formulario
            setIsOrganizacionesDisabled(true)
            setIsParticipantesDisabled(true)

            setIsTipoAcuerdoDisabled(true); // Habilita el campo de proyectos

            return; // Salir de la función si no hay proyectos seleccionados
        } else {

            setSelectedMunicipios(value); // Actualiza la lista de municipios seleccionados
            setSelectedProyectos([]); // Limpia los proyectos seleccionados
            form.setFieldsValue({ proyectos: [] }); // Limpia el campo del formulario
            setIsProyectosDisabled(false); // Habilita el campo de proyectos

            // Filtrar los proyectos por los municipios seleccionados usando 'value' directamente
            const proyectosFiltrados = proyectos.filter(proyecto =>
                value.includes(proyecto.oid_municipio)
            );

            // Eliminar duplicados basados en el 'oid_proyecto'
            const proyectosUnicos = Array.from(
                new Set(proyectosFiltrados.map(proyecto => proyecto.oid_proyecto))
            ).map(oid =>
                proyectosFiltrados.find(proyecto => proyecto.oid_proyecto === oid)
            );

            setProyectosxmunicipio(proyectosUnicos); // Actualiza el estado de proyectos filtrados
        }


    };


    const handleProyectosChange = async (value) => {
        // Verificar si `value` es nulo, indefinido, una cadena vacía o un array vacío
        if (!value || value.length === 0 || (typeof value === 'string' && value.trim() === '')) {
            setTipoAcuerdo(''); // Limpia los tipo acuerdo
            form.setFieldsValue({ tipo_acuerdo: '' }); // Limpia el campo del formulario
            form.setFieldsValue({ organizaciones: '' }); // Limpia el campo del formulario
            setIsOrganizacionesDisabled(true)
            setIsParticipantesDisabled(true)
            setIsTipoAcuerdoDisabled(true); // Habilita el campo de proyectos
        } else {
            setSelectedProyectos(value); // Actualiza la lista de municipios seleccionados
            setIsTipoAcuerdoDisabled(false) // Habilita campo tipo de acuerdo
            if (tipoAcuerdo != '') {
                handleTipoacuerdo(tipoAcuerdo, value)
            }
        }
    };


    const onChangeTipoacuerdo = async (event) => {
        let value = event.target.value;

        if (!value || value.length === 0 || (typeof value === 'string' && value.trim() === '')) {
            setIsOrganizacionesDisabled(true); // deshabilita el campo organizaciones
            setIsParticipantesDisabled(true); // deshabilita el campo participantes
            form.setFieldsValue({ organizaciones: [] }); // Limpia el campo del formulario
            form.setFieldsValue({ participantes: [] }); // Limpia el campo del formulario
        } else {
            setTipoAcuerdo(value);

            switch (value) {
                case 'Asociativo':
                    try {
                        setIsOrganizacionesDisabled(false); // Habilita el campo organizaciones
                        setIsParticipantesDisabled(true); // deshabilita el campo participantes
                        obtenerOrganizaciones(selectedProyectos)
                    } catch (error) {

                    }
                    break;

                case 'Colectivo':
                    try {
                        obtenerOrganizaciones(selectedProyectos)
                        obtenerParticipantes(selectedProyectos)
                        setIsOrganizacionesDisabled(false); // Habilita el campo organizaciones
                        setIsParticipantesDisabled(false) //Habitilita campoparticipantes

                    } catch (error) {
                    }
                    break;

                case 'Individual':
                    try {
                        obtenerParticipantes(selectedProyectos)
                        setIsOrganizacionesDisabled(true); // deshabilita el campo organizaciones
                        setIsParticipantesDisabled(false) //Habitilita campoparticipantes

                    } catch (error) {
                    }
                    break;

                default:

                    setIsOrganizacionesDisabled(true); // deshabilita el campo organizaciones
                    setIsParticipantesDisabled(true) //deshabilitar campoparticipantes


            }
        }
    };

    const handleTipoacuerdo = async (value, proyectos) => {
        if (!value || value.length === 0 || (typeof value === 'string' && value.trim() === '')) {
            setIsOrganizacionesDisabled(true); // deshabilita el campo organizaciones
            setIsParticipantesDisabled(true); // deshabilita el campo participantes
            form.setFieldsValue({ organizaciones: [] }); // Limpia el campo del formulario
            form.setFieldsValue({ participantes: [] }); // Limpia el campo del formulario
        } else {
            setTipoAcuerdo(value);

            switch (value) {
                case 'Asociativo':
                    try {
                        setIsOrganizacionesDisabled(false); // Habilita el campo organizaciones
                        setIsParticipantesDisabled(true); // deshabilita el campo participantes
                        obtenerOrganizaciones(proyectos)
                    } catch (error) {

                    }
                    break;

                case 'Colectivo':
                    try {
                        obtenerOrganizaciones(proyectos)
                        obtenerParticipantes(proyectos)
                        setIsOrganizacionesDisabled(false); // Habilita el campo organizaciones
                        setIsParticipantesDisabled(false) //Habitilita campoparticipantes

                    } catch (error) {
                    }
                    break;

                case 'Individual':
                    try {
                        obtenerParticipantes(selectedProyectos)
                        setIsOrganizacionesDisabled(true); // deshabilita el campo organizaciones
                        setIsParticipantesDisabled(false) //Habitilita campoparticipantes

                    } catch (error) {
                    }
                    break;

                default:

                    setIsOrganizacionesDisabled(true); // deshabilita el campo organizaciones
                    setIsParticipantesDisabled(true) //deshabilitar campoparticipantes


            }
        }
    }

    const obtenerOrganizaciones = async (proyectos) => {
        try {
            setSelectedOrganizaciones([]); // Limpia las organizaciones seleccionados
            form.setFieldsValue({ organizaciones: [] }); // Limpia el campo del formulario
            // Obtener las organizaciones
            const { status, data } = await getOrganizacionesxproyectos(proyectos);
            setOrganizacionesxMunicipios(data)

        } catch (error) {
        }
    }


    const obtenerParticipantes = async (proyectos) => {

        try {
            setSelectedParticipantes([]); // Limpia los participantes seleccionados
            form.setFieldsValue({ participantes: [] }); // Limpia el campo del formulario
            // Obtener las organizaciones
            const { status, data } = await getParticipantesxproyectos(proyectos);
            setParticipantesxproyecto(data)

        } catch (error) {
        }
    }

    // Función para manejar el cambio de archivos
    const handleFileChange = ({ fileList }) => {
        setFiles(fileList);  // Actualiza el estado con los archivos seleccionados
    };

    const handleEdit = async (record) => {
        try {

            // Obtener el campo detalle_acuerdo del objeto record
            const detalleAcuerdoString = record.detalle_acuerdo;
            // Convertir el campo a JSON
            const detalleAcuerdoJSON = JSON.parse(detalleAcuerdoString);
            // Agregar un nuevo campo al objeto JSON

            detalleAcuerdoJSON.oid = record.oid;  //Agregar el id del registro al objeto json

            setEditingUser(detalleAcuerdoJSON);
            setIsModalVisible(true);
            setIsEditing(true);


        } catch (error) {
        }


    };

    // useEffect para ajustar el formulario en modo edición
    useEffect(() => {
        fetchAcuerdosComerciales();
        if (isEditing && editingUser) {
            setIsMunicipiosDisabled(false)
            const departamento = Number(editingUser.departamento);
            const municipiosNumeros = editingUser.municipios?.map(item => Number(item)) ?? [];
            const proyectosNumeros = editingUser.proyectos?.map(item => Number(item)) ?? [];
            const organizacionesNumeros = editingUser.organizaciones?.map(item => Number(item)) ?? [];
            const participantesNumeros = editingUser.participantes ? editingUser.participantes.map(item => Number(item)) : [];

            const tipo_acuerdo = editingUser.tipo_acuerdo;
            setSelectedDepartamento(departamento)
            handleMunicipiosChange(municipiosNumeros)
            handleProyectosChange(proyectosNumeros)
            handleTipoacuerdo(tipo_acuerdo, proyectosNumeros)

            form.setFieldsValue({
                nombre_acuerdo: editingUser.nombre_acuerdo,
                fecha_acuerdo: editingUser.fecha_acuerdo
                    ? moment(editingUser.fecha_acuerdo)
                    : null,
                tipo_alcance: editingUser.tipo_alcance,
                departamento: departamento,
                municipios: municipiosNumeros,
                proyectos: proyectosNumeros,
                tipo_acuerdo: tipo_acuerdo,
                producto_comercial: editingUser.producto_comercial,
                comprador: editingUser.comprador,
                organizaciones: organizacionesNumeros,
                participantes: participantesNumeros
            });

            // Obtener archivos y ajustar estado del Drag-and-Drop
            const fetchFiles = async () => {
                const result = await getArchivosAcuerdosComerciales(editingUser.oid); // Ajusta el id según tu estructura
                if (result.status === 200) {
                    const filesFromServer = result.data.map(file => ({
                        uid: file.filename, // Identificador único
                        name: file.filename,
                        status: 'done',
                        url: `${baseUrl}${file.url}`,
                    }));
                    setFiles(filesFromServer);
                }
            };

            fetchFiles();
        } else {
            form.resetFields()
            setFiles([]); // Limpiar los archivos si no se está editando

        }
    }, [isEditing, editingUser], [nombre_acuerdo], [tipoAcuerdo]);


    const handleSubmit = async (values) => {
        try {
            const formData = new FormData(); // Usamos FormData para enviar archivos

            // Formatear la fecha_acuerdo a 'YYYY-MM-DD'
            const formattedValues = {
                ...values,
                fecha_acuerdo: values.fecha_acuerdo ? values.fecha_acuerdo.format('YYYY-MM-DD') : null,
            };

            // Añadir otros valores del formulario a FormData
            for (let key in formattedValues) {
                if (Array.isArray(formattedValues[key])) {
                    // Añadir cada elemento del array como un campo separado en FormData
                    formattedValues[key].forEach(item => {
                        formData.append(`${key}[]`, item); // No conviertas a número aquí si no es necesario
                    });
                } else {
                    formData.append(key, formattedValues[key]);
                }
            }

            // Añadir los archivos a FormData
            // Variable para almacenar los nombres de archivos existentes
            const existingFilesArray = [];
            let hasNewFiles = false;  // Variable para determinar si hay archivos nuevos

            // Procesar los archivos
            files.forEach(file => {
                if (file.originFileObj) {
                    // Archivos nuevos
                    formData.append('soportes', file.originFileObj);
                    hasNewFiles = true;  // Se encontró al menos un archivo nuevo
                } else {
                    // Archivos existentes
                    existingFilesArray.push(file.name);
                    hasNewFiles = true;  // Se encontró al menos un archivo nuevo

                }
            });

            // Agregar el estado de soportes
            formData.append('estado_soportes', hasNewFiles ? true : false);

            // Convertir existingFilesArray a un JSON string y agregarlo a formData
            formData.append('existingFiles', JSON.stringify(existingFilesArray));



            let response;
            if (isEditing && editingUser) {
                // Añadir el ID del acuerdo comercial al FormData
                formData.append('oid', editingUser.oid);

                // Modo de edición - actualizar un acuerdo comercial
                response = await updateAcuerdoComercial(formData); // Asegúrate de que updateAcuerdoComercial maneje FormData
            } else {
                // Modo de creación - insertar nuevo acuerdo comercial
                response = await createAcuerdoComercial(formData); // Asegúrate de que createAcuerdoComercial maneje FormData
            }

            const successMessage = isEditing
                ? "Acuerdo comercial actualizado correctamente"
                : "Acuerdo comercial registrado correctamente";

            notification.success({
                message: 'Éxito',
                description: successMessage,
            });

            fetchAcuerdosComerciales();
            setIsModalVisible(false);
            form.resetFields(); // Limpiar el formulario después de un envío exitoso
            setFiles([]); // Limpiar los archivos después de enviar el formulario

        } catch (err) {
            notification.error({
                message: 'Error',
                description: 'Error al crear el acuerdo comercial. Inténtelo de nuevo.',
            });
        }
    };

    const handleConfirm = () => {
        form.validateFields().then(values => {
            handleSubmit(values);
        }).catch(info => {
        });
    };
    const handleCancelConfirm = () => {
        notification.info({
            message: 'Info',
            description: 'El envío del formulario ha sido cancelado',
        });
    };

    const fetchAcuerdosComerciales = async () => {
        try {
            const { status, data } = await getAcuerdosComerciales();
            setStatus(status);

            if (status === 200) {

                setAcuerdosComerciales(data);
                // Obtener departamentos únicos para filtros
                const uniqueDepartamentosFilter = [...new Set(data.map(acm => acm.departamento))];
                const filtersDepartamentos = uniqueDepartamentosFilter.map(dep => ({ text: dep, value: dep }));
                setDepartamentosFilters(filtersDepartamentos);

                // Obtener tipo de acuerdo únicos para filtros
                const uniqueTipoacuerdoFilter = [...new Set(data.map(acm => acm.tipo_acuerdo))];
                const filtersTipoacuerdo = uniqueTipoacuerdoFilter.map(tip => ({ text: tip, value: tip }));
                setTipoacuerdoFilters(filtersTipoacuerdo);

                // Obtener tipo de alcance únicos para filtros
                const uniqueTipoalcanceFilter = [...new Set(data.map(acm => acm.tipo_alcance))];
                const filtersTipoalcance = uniqueTipoalcanceFilter.map(tip_a => ({ text: tip_a, value: tip_a }));
                setTipoAlcanceFilters(filtersTipoalcance);

                const { status, dataProyectosxmunicipio } = await getProyectosxmunicipio();
                // Extraer departamentos únicos
                const uniqueDepartamentos = [...new Map(dataProyectosxmunicipio.map(item =>
                    [item.oid_departamento, { id: item.oid_departamento, name: item.departamento }]
                )).values()];

                setDepartamentos(uniqueDepartamentos)

                // Obtener municipios únicos
                const uniqueMunicipios = dataProyectosxmunicipio.reduce((acc, item) => {
                    // Verifica si ya existe un municipio con el mismo id en la lista acumulada
                    if (!acc.some(municipio => municipio.id === item.oid_municipio)) {
                        acc.push({
                            id: item.oid_municipio,
                            name: item.municipio,
                            departamentoId: item.oid_departamento
                        });
                    }
                    return acc;
                }, []);

                setMunicipios(uniqueMunicipios)
                setProyectos(dataProyectosxmunicipio)
            } else {
                setProyectos([]);
            }
        } catch (error) {
            setStatus(500);
            setProyectos([]);
        } finally {
            setLoading(false);
        }
    };

    //Funcion para mensajesdependiendo el estado de la solicitud al backend
    const renderErrorMessage = (status) => {
        if (status === 404) {
            return (
                <Alert type="warning" message="Advertencia" description="Lo sentimos, no se encuentran acuerdos comerciales disponibles para mostrar." showIcon icon={<FontAwesomeIcon icon={faExclamationTriangle} />} />
            );
        } else if (status === 500) {
            return (
                <Alert type="error" message="Error" description="Lo sentimos, ha ocurrido un error al obtener los acuerdos comerciales. Por favor, inténtelo de nuevo más tarde o contacte a soporte@comunix.co" showIcon icon={<FontAwesomeIcon icon={faExclamationCircle} />} />
            );
        }
    };

    const handleTableChange = (pagination, filters, sorter, extra) => {
        setPagination(pagination);
        setSortOrder(sorter.order);
        setSortField(sorter.field);
        setFilteredInfo(filters);
    };


    //Configuración d elas columnas d ela tabla
    const noDataConfig = {
        emptyText: (
            <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <InboxOutlined style={{ fontSize: '2rem' }} />
                <span>Sin resultados</span>
            </span>
        ),
    };


    const exportToExcel = () => {
        const fileExtension = '.xlsx';
        const fileName = 'Acuerdos_comerciales_UTF_COL_160' + fileExtension;

        // Crear un nuevo libro de trabajo
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Acuerdos_comerciales');

        // Encabezado título
        worksheet.mergeCells('A1:L1'); // Titulo
        worksheet.getCell('A1').value = 'LISTA DE ACUERDOS COMERCIALES';

        // Ajustar el formato específico para la fila 1
        const row1 = worksheet.getRow(1);
        row1.eachCell({ includeEmpty: true }, (cell) => {
            cell.font = { size: 16, bold: true }; // Aplicar negrita y tamaño de fuente 16
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true, // Habilitar ajuste de texto
            };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "5b9bd5" }, // Color de fondo
            };
        });

        const datos = [
            'ID',
            'Departamento',
            'Municipio',
            'Fecha del acuerdo',
            'Tipo de acuerdo',
            'Alcance del acuerdo',
            'Producto comercial',
            'Comprador',
            'Nombre del acuerdo',
            'Proyectos',
            'Organizaciones',
            'Integrantes'
        ];

        // Configurar el ancho fijo de las columnas
        const columnWidth = 30; // Ajusta el ancho según sea necesario
        worksheet.columns = datos.map(() => ({ width: columnWidth }));

        // Agregar fila de encabezado
        worksheet.addRow(datos);

        // Ajustar el formato específico para la fila 2
        const row2 = worksheet.getRow(2);
        row2.eachCell({ includeEmpty: true }, (cell) => {
            cell.font = {
                size: 14,
                bold: true
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true, // Habilitar ajuste de texto
            };
        });

        // Mapear y seleccionar todos los campos de participantes
        const dataToExport = acuerdosComerciales.map(acm => [
            acm.oid || '', // Si es null o undefined, reemplaza con ''
            acm.departamento || '',
            acm.municipios || '',
            acm.fecha_acuerdo || '',
            acm.tipo_acuerdo || '',
            acm.tipo_alcance || '',
            acm.producto_comercial || '',
            acm.comprador || '',
            acm.nombre_acuerdo || '',
            acm.proyectos || '',
            acm.organizaciones || '',
            acm.participantes || ''
        ]);

        // Agregar los datos a la hoja
        worksheet.addRows(dataToExport);

        // Aplicar color de fondo a todas las celdas desde A hasta L en todas las filas a partir de la fila 2
        const numRows = worksheet.rowCount; // Obtén el número total de filas
        for (let rowNum = 2; rowNum <= numRows; rowNum++) {
            const row = worksheet.getRow(rowNum);
            row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                cell.alignment = {
                    vertical: 'middle', // Centrar verticalmente
                    wrapText: true // Ajustar texto
                };
            });
        }

        // Ajustar el formato de todas las celdas
        worksheet.eachRow(
            { includeEmpty: true },
            function (row, rowNumber) {
                row.eachCell(
                    function (cell, colNumber) {
                        cell.border = {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        }; // Bordes
                    }
                );
            }
        );

        // Guardar el archivo Excel
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        });
    };


    //Función para buscar un participante
    const handleSearchInputChange = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        const filteredAcuerdos = acuerdosComerciales.filter(acm =>
            acm.nombre_acuerdo.toLowerCase().includes(searchTerm)
        );
        setAcuerdosComerciales(filteredAcuerdos);

    };

    //Función para limpiar filtro de busqueda
    const clearAllFilters = async () => {
        setFilteredInfo({});
        setSearchTerm('');
        setDepartamentosFilters('')
        setTipoAlcanceFilters('')
        setTipoacuerdoFilters()
        try {
            const { status, data } = await getAcuerdosComerciales();
            if (status === 200) {
                if (nombre_acuerdo) {

                    const filteredData = data.filter(acm => acm.nombre_acuerdo === parseInt(nombre_acuerdo));
                    setAcuerdosComerciales(filteredData || []);
                } else {
                    setAcuerdosComerciales(data || []);
                }
            } else {
                setAcuerdosComerciales([]);
            }
        } catch (error) {
            setAcuerdosComerciales([]);
        }
    };

    const handleCancel = () => {
        if (form.isFieldsTouched()) {
            Modal.confirm({
                title: '¿Estás seguro?',
                content: 'Si cierras el modal, se perderán los datos no guardados.',
                okText: 'Sí, cerrar',
                cancelText: 'Cancelar',
                onOk() {
                    setIsProyectosDisabled(true);
                    setIsTipoAcuerdoDisabled(true);
                    setIsOrganizacionesDisabled(true);
                    setIsParticipantesDisabled(true)
                    setSelectedDepartamento([])
                    setSelectedMunicipios([])
                    setSelectedOrganizaciones([])
                    setSelectedProyectos([])
                    setSelectedParticipantes([])
                    setIsModalVisible(false);
                    setIsEditing(false);
                    setEditingUser(null);
                    setTipoAcuerdo('');
                    form.resetFields();
                }
            });
        } else {
            setIsProyectosDisabled(true);
            setIsTipoAcuerdoDisabled(true);
            setIsOrganizacionesDisabled(true);
            setIsParticipantesDisabled(true)

            setIsModalVisible(false);
            form.resetFields();
        }
    };

    const showModal = () => {
        form.resetFields(); // Resetear los campos del formulario
        setIsMunicipiosDisabled(true); // Deshabilitar el campo de municipios inicialmente
        setIsProyectosDisabled(true); // Deshabilitar el campo de municipios inicialmente
        setIsEditing(false);
        setIsModalVisible(true);
        setTipoAcuerdo('');
    };


    // Función para reemplazar asteriscos por guion y salto de línea
    const formatText = (text) => {
        // Verificar si el texto es nulo o vacío
        if (text == null || text.trim() === '') {
            return null; // O cualquier otra cosa que prefieras si text es nulo o vacío
        }

        // Procesar el texto si no es nulo ni vacío
        return text.split('*').map((line, index) => (
            line.trim() ? <div key={index}> - {line.trim()}</div> : null
        ));
    };

 // Función para crear un renderizador de título con tooltip
 const createTitleRenderer = (tooltipColor = '#01060E', tooltipPlacement = 'right', maxLength = 20) => {
    return (text) => {
      // Limita la longitud del texto visible
      const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
      const tooltipTitle = text || 'Sin título';

      return (
        <Tooltip title={tooltipTitle} color={tooltipColor} placement={tooltipPlacement}>
          <span>{truncatedText}</span>
        </Tooltip>
      );
    };
  };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'oid',
            key: 'oid',
            width: 50,
            align: 'center',
            filteredValue: null,
        },
        
        
        {
            title: 'Documento',
            dataIndex: 'estado_soporte',
            key: 'estado_soporte',
            width: 150,
            filteredValue: null,
            render: (estado) => {
                const isEstado = estado === 'true' ? true : estado === 'false' ? false : estado;
                return (
                    <Tag color={isEstado ? 'green' : 'red'}>
                        {isEstado ? 'Existe' : 'No existe'}
                    </Tag>
                );
            },
        },
       
        {
            title: 'Departamento',
            dataIndex: 'departamento',
            key: 'departamento',
            sorter: {
                compare: (a, b) => a.departamento.localeCompare(b.departamento),
                multiple: 3,
            },
            filterSearch: true,
            filters: departamentosFilters,
            filteredValue: filteredInfo.departamento || null,
            onFilter: (value, record) => (record.departamento || '').startsWith(value),
            width: 180,
        },
        {

            title: 'Municipio',
            dataIndex: 'municipios',
            key: 'municipios',
            width: 200,
            align: 'left',
            filteredValue: null,
            render: text => formatText(text),
            render: createTitleRenderer() // Usa el renderizador de tooltip
        },
        {
            title: 'Tipo de acuerdo',
            dataIndex: 'tipo_acuerdo',
            key: 'tipo_acuerdo',
            sorter: {
                compare: (a, b) => a.tipo_acuerdo.localeCompare(b.tipo_acuerdo),
                multiple: 3,
            },
            filterSearch: true,
            filters: tipoAcuerdoFilters,
            filteredValue: filteredInfo.tipo_acuerdo || null,
            onFilter: (value, record) => (record.tipo_acuerdo || '').startsWith(value),
            width: 200,
            

        },

        {
            title: 'Tipo de alcance',
            dataIndex: 'tipo_alcance',
            key: 'tipo_alcance',
            sorter: {
                compare: (a, b) => a.tipo_alcance.localeCompare(b.tipo_alcance),
                multiple: 3,
            },
            filterSearch: true,
            filters: tipoAlcanceFilters,
            filteredValue: filteredInfo.tipo_alcance || null,
            onFilter: (value, record) => (record.tipo_alcance || '').startsWith(value),
            width: 200,

        },
        {
            title: 'Proyecto',
            dataIndex: 'proyectos',
            key: 'proyectos',
            width: 200,
            align: 'left',
            filteredValue: null,
            render: text => formatText(text),
            render: createTitleRenderer() // Usa el renderizador de tooltip

        },
        {
            title: 'Organizaciones',
            dataIndex: 'organizaciones',
            key: 'organizaciones',
            width: 200,
            align: 'left',
            filteredValue: null,
            render: text => formatText(text),
            render: createTitleRenderer() // Usa el renderizador de tooltip


        },

        {

            title: 'Integrantes',
            dataIndex: 'participantes',
            key: 'participantes',
            width: 200,
            align: 'left',
            render: text => formatText(text),
            render: createTitleRenderer() // Usa el renderizador de tooltip
        },

        {
            title: 'Fecha Acuerdo',
            dataIndex: 'fecha_acuerdo',
            key: 'fecha_acuerdo',
            width: 200,
            align: 'left',
            filteredValue: null,


        },

        {
            title: 'Producto comercial',
            dataIndex: 'producto_comercial',
            key: 'producto_comercial',
            width: 200,
            align: 'center',
            filteredValue: null,
            render: createTitleRenderer() // Usa el renderizador de tooltip

        },
        {
            title: 'Comprador',
            dataIndex: 'comprador',
            key: 'comprador',
            width: 200,
            align: 'left',
            filteredValue: null,
            render: createTitleRenderer() // Usa el renderizador de tooltip

        },
        {
            title: 'Nombre del acuerdo',
            dataIndex: 'nombre_acuerdo',
            key: 'nombre_acuerdo',
            width: 200,
            align: 'left',
            filteredValue: null,
            render: createTitleRenderer() // Usa el renderizador de tooltip

        },
        {
            title: '', // Columna que no se mostrará
            dataIndex: 'detalle_acuerdo',
            key: 'detalle_acuerdo',
            filteredValue: null,
            render: () => null, // No mostrar ningún contenido en la celda
        },
    ];

 // Agregar la columna "Acciones" al principio si permissionExport es verdadero
if (permissionUpdate) {
    columns.unshift({
        title: "Acciones",
        dataIndex: "actions",
        key: "actions",
        width: 100,
        filteredValue: null,
        render: (text, record) => (
            <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => handleEdit(record)}
            />
        ),
    });
}
    if (loading) {
        return <div className="text-center"><Spin size="large" /></div>;
    }

    return (
        <div className="container">
            <React.Fragment>
                {status === 404 || status === 500 ? (
                    renderErrorMessage(status)
                ) : (
                    <React.Fragment>
                        <div className="row mb-2 d-flex align-items-center">
                            <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                                <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                                    <p>PROYECTO UTF <br /> COL 160 COL</p>
                                </div>
                                <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                                    <p>Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET</p>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                                <h2 className="text-center mb-2">Acuerdos comerciales</h2>
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <div className="input-group shadow-sm">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Buscar acuerdo comercial..."
                                                value={searchTerm} // Vincula el estado de búsqueda al input
                                                onChange={handleSearchInputChange} // Maneja el cambio en el input
                                            />
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onChange={handleSearchInputChange} // Maneja el cambio en el input
                                            >
                                                <SearchOutlined /> {/* Incluye el icono aquí */}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-group shadow-sm">

                                            {permissionInsert && (
                                                <Button
                                                    type="danger"
                                                    className="btn btn-primary me-2"
                                                    onClick={showModal}
                                                    size="large"
                                                    icon={<PlusOutlined />}
                                                >
                                                    Nueva
                                                </Button>

                                            )}
                                            {permissionExport && (
                                                <Button
                                                    type="danger"
                                                    className="btn btn-primary me-2"
                                                    onClick={exportToExcel}
                                                    size="large"
                                                    icon={<DownloadOutlined />}
                                                >
                                                    Excel
                                                </Button>
                                            )}



                                            <Button
                                                type="danger"
                                                className="btn btn-primary"
                                                onClick={clearAllFilters}
                                                size="large"
                                                icon={<ClearOutlined />}
                                            >
                                                Limpiar
                                            </Button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {acuerdosComerciales.length > 0 ? (
                            viewMode === 'table' ? (
                                <Table
                                    columns={columns}
                                    dataSource={acuerdosComerciales}
                                    rowKey='oid'
                                    bordered
                                    pagination={pagination}
                                    onChange={handleTableChange}
                                    sortDirections={['ascend', 'descend']}
                                    sortOrder={sortOrder}
                                    onSorterChange={setSortOrder}
                                    sortField={sortField}
                                    locale={noDataConfig}
                                    loading={loading}
                                    filteredInfo={filteredInfo}
                                    scroll={{ y: 500, x: 'max-content' }}
                                />
                            ) : (
                                <p className="text-center">No hay acuerdos comerciales para mostrar.</p>
                            )
                        ) : (
                            <p className="text-center">No hay acuerdos comerciales para mostrar.</p>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
            <Modal
                title={<h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>{isEditing ? "Editar acuerdo comercial" : "Registrar acuerdo comercial"}</h2>}
                style={{ top: 20 }}
                width={1000}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancelar
                    </Button>,
                    <Popconfirm
                        key="confirm"
                        title="¿Estás seguro de enviar el formulario?"
                        onConfirm={handleConfirm}
                        onCancel={handleCancelConfirm}
                        okText="Sí"
                        cancelText="No"
                    >
                        <Button type="primary">
                            {isEditing ? 'Actualizar' : 'Registrar'}
                        </Button>
                    </Popconfirm>,
                ]}
            >
                <Form form={form} layout="vertical">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="fecha_acuerdo"
                                label="Fecha del acuerdo"
                            >
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Nombre del acuerdo"
                                name="nombre_acuerdo"
                                rules={[{ required: true, message: 'Por favor diligencia un nombre de acuerdo!' }]}
                            >
                                <TextArea placeholder="Escribe nombre del acuerdo comercial" autoSize />

                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Departamento"
                                name="departamento"
                                rules={[{ required: true, message: 'Seleccione un departamento' }]}
                            >
                                <Select
                                    placeholder="Seleccione un departamento"
                                    onChange={handleDepartamentoChange}
                                    value={selectedDepartamento}
                                >
                                    {departamentos.map((d) => (
                                        <Option key={d.id} value={d.id}>{d.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Municipios"
                                name="municipios"
                                rules={[{ required: true, message: 'Seleccione al menos un municipio' }]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Seleccione municipios"
                                    onChange={handleMunicipiosChange}
                                    value={selectedMunicipios}
                                    disabled={isMunicipiosDisabled} // Deshabilita hasta que se seleccione un departamento
                                >
                                    {municipios
                                        .filter((m) => m.departamentoId === selectedDepartamento) // Filtra los municipios por el departamento seleccionado
                                        .map((m) => (
                                            <Option key={m.id} value={m.id}>{m.name}</Option>
                                        ))}
                                </Select>
                            </Form.Item>


                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Proyecto"
                                name="proyectos"
                                rules={[{ required: true, message: 'Seleccione al menos un proyecto' }]}
                            >

                                <Select
                                    mode="multiple"
                                    placeholder="Seleccione un proyecto"
                                    value={selectedProyectos}
                                    onChange={handleProyectosChange}
                                    disabled={isProyectosDisabled}
                                    showSearch // Habilita la funcionalidad de búsqueda
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    } // Filtro para la búsqueda
                                >
                                    {proyectosxmunicipio.map(p => (
                                        <Option key={p.oid_proyecto} value={p.oid_proyecto}>
                                            {p.codigo_proyecto + ' - ' + p.proyecto}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>


                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Tipo de acuerdo"
                                name="tipo_acuerdo"
                                rules={[{ required: true, message: 'Seleccione un tipo de acuerdo' }]}
                            >
                                <Radio.Group options={optionsTipoactividad} onChange={onChangeTipoacuerdo} value={tipoAcuerdo} optionType="button" disabled={isTipoAcuerdoDisabled} />

                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Alcance del acuerdo"
                                name="tipo_alcance"
                                rules={[{ required: true, message: 'Seleccione un tipo de alcance de acuerdo' }]}
                            >
                                <Radio.Group value={tipoAlcance}>
                                    <Radio value={'Firmado'}>Firmado</Radio>
                                    <Radio value={'No Ffirmado'}>No firmado</Radio>
                                </Radio.Group>

                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        {!isOrganizacionesDisabled && (
                            <Col span={12}>
                                <Form.Item
                                    label="Organizaciones"
                                    name="organizaciones"
                                    rules={[{ required: true, message: 'Seleccione una organización' }]}
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Seleccione una organización"
                                        value={selectedOrganizaciones}
                                        showSearch // Habilita la funcionalidad de búsqueda
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        } // Filtro para la búsqueda
                                    >
                                        {organizacionesxMunicipios.map((i) => (
                                            <Option key={i.oid} value={i.oid}>{i.sigla}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}

                        {!isParticipantesDisabled && (
                            <Col span={12}>
                                <Form.Item
                                    label="Participantes"
                                    name="participantes"
                                    rules={[{ required: true, message: 'Seleccione un participante' }]}
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Seleccione una participante"
                                        value={selectedParticipantes}
                                        showSearch // Habilita la funcionalidad de búsqueda
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        } // Filtro para la búsqueda
                                    >
                                        {participantesxproyecto.map((p) => (
                                            <Option key={p.oid} value={p.oid}> {p.numero_identificacion + ' - ' + p.nombre_participante}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Productos comerdiales"
                                name="producto_comercial"
                                rules={[{ required: true, message: 'Por favor diligencia un producto comercial!' }]}>
                                <TextArea placeholder="Escribe productos comerciales" autoSize />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Comprador"
                                name="comprador"
                                rules={[{ required: true, message: 'Por favor diligencia un comprador!' }]}>
                                <TextArea placeholder="Escribe comprador" autoSize />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Soportes" name="soportes">
                                <Dragger
                                    multiple
                                    beforeUpload={() => false}  // Desactiva la subida automática
                                    fileList={files}
                                    onChange={handleFileChange}
                                    showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        Haga clic o arrastre los archivos a esta área para cargarlos.
                                    </p>
                                    <p className="ant-upload-hint">
                                        Soporte para una carga única o masiva.
                                    </p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default AcuerdosComerciales;