// components/UserProfile.jsx
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Button, Modal, Typography, Dropdown, Menu } from 'antd';
import { UserOutlined, LockOutlined, DownOutlined } from '@ant-design/icons';
import './UserProfile.css';  // Asegúrate de definir estilos si es necesario

const { Text } = Typography;

const UserProfile = () => {
    const { user } = useSelector((state) => state.auth);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleChangePassword = () => {
        // Aquí puedes agregar la lógica para cambiar la contraseña
        setIsModalVisible(false); // Cierra el modal después de cambiar la contraseña
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <Text strong>{user ? user.name : 'Nombre del Usuario'}</Text>
            </Menu.Item>
            <Menu.Item key="2">
                <Text type="secondary">{user ? `Rol: ${user.role}` : 'Rol: Usuario'}</Text>
            </Menu.Item>
            <Menu.Item key="3">
                <Button
                    type="primary"
                    icon={<LockOutlined />}
                    onClick={showModal}
                    style={{ width: '100%' }}
                >
                    Cambiar Contraseña
                </Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="user-profile">
            <Dropdown overlay={menu} trigger={['click']}>
                <div className="avatar-container">
                    <Avatar size={64} icon={<UserOutlined />} />
                    <DownOutlined className="dropdown-icon" />
                </div>
            </Dropdown>

            <Modal
                title="Cambiar Contraseña"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <p>Formulario para cambiar contraseña va aquí.</p>
                <Button type="primary" onClick={handleChangePassword}>Cambiar Contraseña</Button>
            </Modal>
        </div>
    );
};

export default UserProfile;
