import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/reset.css';
import './LoginBodecom.css';
import logoBodecom from '../../assets/bodecom.png';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/auth/authSlice';

const LoginBodecom = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [attemptedLogin, setAttemptedLogin] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, user, applications, error } = useSelector((state) => state.auth);

    useEffect(() => {

        if (user && user.username) {
            if (user.applications && user.applications.length === 0) {
                message.error('El usuari@ no tiene aplicaciones asignadas.', 5);
            } else {
                message.success('Bienvenid@ UTF COL 160 COL!');
                navigate('/inicio');


            }
        } else if (attemptedLogin && error) {
            message.error('Error en la autenticación del usuario.', 5);
        }
    }, [user, error, navigate, attemptedLogin]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setAttemptedLogin(true);
        dispatch(login({ username, password }));
    };

    return (
        <div className="login-background">
            <Container className="login-container d-flex justify-content-end align-items-center">
                <Row>
                    <Col md={4} className="login-form-wrapper">
                        <div className="login-form-box">
                            <img
                                src={logoBodecom}
                                alt="Bodecom Logo"
                                className="logo pb-3"
                                width={250}
                            />
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formBasicUsername">
                                    <Form.Label className='titleLabel'>Usuario</Form.Label>
                                    <Input
                                        type="text"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        placeholder="Digite usuario"
                                        className="mb-3"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label className='titleLabel'>Clave</Form.Label>
                                    <Input.Password
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Digite contraseña"
                                        className="mb-3"
                                        required
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="w-100" disabled={loading}>
                                    {loading ? 'Iniciando sesión...' : 'Iniciar sesión'}
                                </Button>
                            </Form>
                            <h2 className="text-center mt-2">Bienvenid@</h2>
                            <h4 className="text-center">
                                <a href="https://www.fao.org/colombia/es/" className="no-decoration-link" target='_blank' rel="noopener noreferrer">www.fao.org</a>
                            </h4>
                            <hr />
                            <h6 className="text-center">soporte@comunix.co</h6>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default LoginBodecom;
