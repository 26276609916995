import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/obtener-arbol-de-reportes';

/**
 * @typedef {Object} GetReportsParams
 * @property {string} directoryPath - The path of the directory.
 * @property {string} [codigoProyecto] - The project code, optional.
 */

/**
 * Fetches reports based on the provided parameters.
 * @param {GetReportsParams} params - Parameters for fetching reports.
 * @returns {Promise<{status: number, data: any}>} The response status and data.
 */
const postReports = async (params) => {

  try {

    const response = await axios.post(`${baseUrl}${endpoint}`, {
      directoryPath: params.directoryPath,
      codigo_proyecto: params.codigoProyecto,
    });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener los reportes:', error);
    throw error; // Lanza el error para ser manejado en el componente React
  }
};

export { postReports };
