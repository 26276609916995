import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const depmunEndpoint = '/api/proyectos/';


// Función para obtener todos los proyectos
export const fetchAllProyectos = async () => {
    try {
        const response = await axios.get(`${baseUrl}${depmunEndpoint}`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener todos los proyectos:', error);
        throw error;
    }
};

// Función para obtener un proyecto por su oid
export const fetchProyectoByOid = async (oid) => {
    try {
        const response = await axios.get(`${baseUrl}${depmunEndpoint}${oid}`);
        return response.data;
    } catch (error) {
        console.error(`Error al obtener el proyecto con oid ${oid}:`, error);
        throw error;
    }
};
