import React, { useEffect, useState, useCallback } from 'react';
import { Card, Typography, Row, Col, Layout, Collapse, Spin, Alert, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { faBroom } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProyectoService from '../../services/estructuracion/get_proyecto_id'; // Ajusta la ruta según sea necesario
import Header160 from '../header/header';
import { postReports } from '../../services/create_tree/get_tree';
import './proyecto_estructuracion.css';
import axios from 'axios';
import config from '../../config';

const { Title, Text } = Typography;
const { Panel } = Collapse;
const baseUrl = config.baseUrl;

const ProyectoView = () => {
    const { oid } = useParams();
    const [proyecto, setProyecto] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [itemSelect, setItemSelect] = useState(null);
    const [nameProyecto, setNameProyecto] = useState(null);


    const descriptionDirectories = [
        { key: "tarea_131", medios: "tarea_131/indicador_13/medio_verificacion_12" },
        { key: "tarea_132", medios: "tarea_132/indicador_14/medio_verificacion_13" },
        { key: "tarea_133", medios: "tarea_133/indicador_15/medio_verificacion_14" },
        { key: "tarea_134", medios: "tarea_134/indicador_16/medio_verificacion_15" },
        { key: "tarea_135", medios: "tarea_135/indicador_17/medio_verificacion_16" },
        { key: "tarea_136", medios: "tarea_136/indicador_18/medio_verificacion_14" },
        { key: "tarea_137", medios: "tarea_137/indicador_19/medio_verificacion_17" },

    ];



    const dataAdition = useCallback(async (tarea) => {

        const directoryPath = config.baseDirEstructuracion;
        const tareaConvertida = 'tarea_' + tarea.replace(/\./g, '');
        const mediosTarea = descriptionDirectories.find(item => item.key === tareaConvertida);

        if (nameProyecto) {
            if (mediosTarea) {
                console.log("Medios encontrados: " + mediosTarea.medios);
            } else {
                console.error("No se encontraron medios para la clave: " + tareaConvertida);
                return new Error("No se encontró ningún medio para la clave: " + tareaConvertida);
            }

            try {
                const path = `${directoryPath}/${mediosTarea.medios}`;

                // Log para ver el path construido y el objeto que se va a enviar
                console.log("Path a enviar: ", path);
                console.log("Código del proyecto: ", nameProyecto);

                // Log para mostrar los detalles de la solicitud
                console.log("Enviando solicitud con los siguientes parámetros:");
                console.log({
                    path: path,
                    codigoProyecto: nameProyecto
                });



                const response = await postReports({ path, codigoProyecto: nameProyecto });

                // Verificar que la respuesta no sea nula o indefinida
                if (response && response.dataNodo) {
                    const dataNodo = response.dataNodo;
                    console.log("Datos recibidos: ", dataNodo);  // Mostramos los datos recibidos
                    return dataNodo;
                } else {
                    throw new Error("La respuesta no contiene el campo 'dataNodo'.");
                }


            } catch (error) {

                console.error("Error al enviar la solicitud o procesar la respuesta: ", error);


                return []; // En caso de error, devuelve una lista vacía
            }
        }
    }, [nameProyecto]);

    useEffect(() => {
        const fetchProyectoData = async () => {
            try {
                console.log("Iniciando fetch de proyecto con OID:", oid);
                const data = await ProyectoService.fetchProyectoByOid(oid);


                const proyectoCarga = `${data.codigo}. ${data.nombre_proyecto}`;

                console.log("Valor Inicial: ", proyectoCarga);

                setNameProyecto(proyectoCarga);

                if (data.actividades) {
                    for (const actividad of data.actividades) {
                        if (actividad.tareas) {
                            console.log("Tareas disponibles: ", actividad.tareas);

                            for (const tarea of actividad.tareas) {
                                const soportes = await dataAdition(tarea.codigo);

                                tarea.indicadores.forEach(indicador => {
                                    indicador.rutas.forEach(ruta => {
                                        ruta.soportes = soportes;
                                    });
                                });
                            }
                        } else {
                            console.log("Esta actividad no tiene tareas.");
                        }
                    }
                } else {
                    console.log("No se encontraron actividades.");
                }

                setProyecto(data);
            } catch (err) {
                console.error("Error al obtener los datos del proyecto:", err);
                setError(err);
            } finally {
                console.log("Fetch de proyecto completado.");
                setLoading(false);
            }
        };

        if (oid) {
            console.log("OID detectado, iniciando fetch:", oid);
            fetchProyectoData();
        } else {
            console.warn("No se proporcionó un OID.");
        }
    }, [oid, dataAdition]);



    if (loading) {
        console.log("Cargando proyecto...");
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh' // Ocupa toda la altura de la ventana
            }}>
                <Spin size="large" />
            </div>
        );
    }


    if (error) {
        console.log("Error encontrado, mostrando mensaje de error.");
        return <div style={{ padding: '20px' }}><Alert message="Error al cargar el proyecto" type="error" /></div>;
    }

    if (!proyecto) {
        console.log("No se encontró el proyecto, mostrando advertencia.");
        return <div style={{ padding: '20px' }}><Alert message="No se encontró el proyecto" type="warning" /></div>;
    }








    // Renderiza el contenido del proyecto aquí
    const handleCardClick = (rutaname) => {

        console.log("Valor de la Ruta", rutaname)

        setModalIsVisible(true)
    };

    const hideModalAvance = () => {
        setModalIsVisible(false)
    };


    return (
        <Layout className="container">
            <div className="header-filter-container d-flex align-items-center justify-content-between">
                <Header160 />
                <div className="container elemtos">
                    <div className="input-group-container">
                        <div className="input-group shadow-sm">
                            <input
                                type="text"
                                className="form-control shadow-sm"
                                placeholder="Buscar por código o nombre"
                            />
                            <button
                                className="btn btn-primary"
                                type="button"
                            >
                                <FontAwesomeIcon icon={faBroom} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ padding: '20px', overflowY: 'auto' }}>
                <Title level={3} className="gradient-text">
                    {proyecto.codigo} - {proyecto.nombre_proyecto}
                </Title>
                <Title level={5} className="justified-title">
                    {proyecto.actividades && proyecto.actividades[0]?.codigo} {proyecto.actividades && proyecto.actividades[0]?.nombre_actividad || 'Nombre de la Actividad'}
                </Title>
                <Collapse accordion>
                    {proyecto.actividades.map((actividad, index) => (
                        <Collapse accordion key={index}>
                            {actividad.tareas.map((tarea, tareaIndex) => (
                                <Panel
                                    header={`${tarea.codigo} - ${tarea.nombre_tarea}`}
                                    key={`tarea-${tareaIndex}`}
                                    style={{ marginBottom: '16px' }}
                                >
                                    <Row gutter={16} justify="center">
                                        {tarea.indicadores.map((indicador, indicadorIndex) => (
                                            indicador.rutas.map((ruta, rutaIndex) => (
                                                Array.isArray(ruta.soportes) && ruta.soportes.length > 0 ? (
                                                    ruta.soportes.map((soporte, soporteIndex) => (
                                                        <Col xs={24} sm={12} md={8} lg={6} key={`soporte-${soporteIndex}`}>
                                                            <Card
                                                                className="custom-card"
                                                                title={<div className="card-title">{ruta.nombre_ruta}</div>}
                                                                bordered={false}
                                                                onClick={() => handleCardClick(indicador)}
                                                            >
                                                                <div className="card-content">
                                                                    <Text
                                                                        style={{
                                                                            color: '#333',
                                                                            fontSize: '16px',
                                                                            display: 'block',
                                                                            textAlign: 'center'
                                                                        }}
                                                                    >
                                                                        {soporte.nombre_soporte}
                                                                    </Text>

                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    ))
                                                ) : (
                                                    <Col span={24} key={`no-soportes-${rutaIndex}`}>
                                                        <div>No se encontraron soportes</div>
                                                    </Col>
                                                )
                                            ))
                                        ))}
                                    </Row>
                                </Panel>
                            ))}
                        </Collapse>
                    ))}
                </Collapse>

            </div>

            <Modal
                title={<span className="custom-modal-title">Medios de verificación</span>}
                open={modalIsVisible}
                onCancel={hideModalAvance}
                footer={null}
                width="90%" // Establecer el ancho en porcentaje de la pantalla
                style={{ top: 20 }} // Opcional: Ajusta la posición vertical del modal
            >

            </Modal>

        </Layout>
    );
};

export default ProyectoView;
