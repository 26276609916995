import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/downloadSupportMER/';

const downloadSupportMER = async (soporteId, nombreSoporte) => {
  try {
    const response = await axios.get(`${baseUrl}${endpoint}${soporteId}`, {
      responseType: 'blob', // Especifica que la respuesta es un blob (archivo binario)
    });

    // Verifica si el encabezado Content-Disposition está presente en la respuesta
    const contentDisposition = response.headers['content-disposition'];


    // Extrae el nombre de archivo del encabezado Content-Disposition si está disponible
    let fileName = nombreSoporte; // Nombre de archivo predeterminado
    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }

    // Crea un objeto URL para el blob recibido
    const url = window.URL.createObjectURL(new Blob([response.data]));
    
    // Crea un enlace temporal para iniciar la descarga
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // Establece el nombre de descarga según el nombre del archivo obtenido

    document.body.appendChild(link);
    link.click();

    // Elimina el enlace del DOM después de la descarga
    document.body.removeChild(link);

    return {
      status: response.status,
      message: 'File downloaded successfully',
    };
  } catch (error) {
    console.error('Error downloading the file:', error);
    return {
      status: error.response ? error.response.status : 500,
      message: 'Error downloading the file',
    };
  }
};



export { downloadSupportMER };
