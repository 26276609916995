import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;



// Función para verificar si el nombre de una carpeta ya existe
const checkFolderName = async (path, folderName, pathRaiz) => {
    try {
        const response = await axios.post(`${baseUrl}/api/check-folder-name`, {
            dirPath: path,
            folderName: folderName,
            folderRaiz: pathRaiz,
        });
        return {
            status: response.status,
            data: response.data,
        };
    } catch (error) {

        throw error; // Lanza el error para ser manejado en el componente React
    }
};

const createFolder = async (path, folderName, folderRaiz, resourceInfo) => {
    try {
        const response = await axios.post(`${baseUrl}/api/create-folder`, {
            dirPath: path,
            folderName: folderName,
            folderRaiz: folderRaiz,
            resourceInfo: resourceInfo,
        });
        return {
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        console.error('Error al crear la carpeta:', error);
        throw error; // Lanza el error para ser manejado en el componente React
    }
};

export { checkFolderName, createFolder };
