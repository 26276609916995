import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/getSupportMER/';

const getSoportesMER = async (oid, tipo) => {
  try {
    const response = await axios.get(`${baseUrl}${endpoint}${oid}/${tipo}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener los soportes MER:', error);
    return {
      status: error.response ? error.response.status : 500,
      message: 'Error al obtener los soportes MER',
    };
  }
};

export { getSoportesMER };
