import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/postSupportMER/';

const guardarSoporteMER = async (formData) => {
  try {
    const response = await axios.post(`${baseUrl}${endpoint}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al guardar el soporte MER:', error);
    return {
      status: error.response ? error.response.status : 500,
      message: 'Error al guardar el soporte MER',
    };
  }
};

export { guardarSoporteMER };
