import axios from 'axios';
import config from '../../config';

// Configurar una instancia de axios
const axiosClient = axios.create({
    baseURL: config.baseUrl,
    timeout: 30000, // Tiempo de espera de 10 segundos
});

const endpoint = '/api/proyecto/';

const ProyectoService = {
    // Función para obtener todos los proyectos
    fetchAllProyectos: async () => {
        try {
            const response = await axiosClient.get(endpoint);
            return response.data;
        } catch (error) {
            console.error('Error al obtener todos los proyectos:', error.message);
            throw new Error('Error al obtener todos los proyectos');
        }
    },

    // Función para obtener un proyecto por su oid
    fetchProyectoByOid: async (oid) => {
        try {
            if (!oid) {
                throw new Error('OID no proporcionado');
            }
            const response = await axiosClient.get(`${endpoint}${oid}`);
            return response.data;
        } catch (error) {
            console.error(`Error al obtener el proyecto con oid ${oid}:`, error.message);
            throw new Error(`Error al obtener el proyecto con oid ${oid}`);
        }
    }
};

export default ProyectoService;
