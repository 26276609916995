import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/proyectos/setEstadoProyecto';

const updateEstadoProyecto = async (codigo, ac_estado) => {
    try {
        const response = await axios.put(`${baseUrl}${endpoint}/${codigo}`, { ac_estado });
        return {
            status: response.data.status,
            message: response.data.message || 'Project state updated successfully',
        };
    } catch (error) {
        console.error('Error updating project state:', error.response ? error.response.data : error.message);
        return {
            status: error.response ? error.response.status : 500,
            message: error.response ? error.response.data.message : 'Error updating project state.',
        };
    }
};

export { updateEstadoProyecto };
