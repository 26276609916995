import React, { useState, useEffect } from 'react';
import { Card, Spin, Layout, Row, Col, Button, Select, Tag, Tooltip } from 'antd';
import { faBroom } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getReports } from '../../services/inf_insumos/get_reports';
import Header160 from '../header/header';
import './insumoReportes.css'; // Importa el archivo CSS donde defines las clases de estilo
import { SettingOutlined, PlusCircleOutlined, CheckSquareOutlined } from '@ant-design/icons';
import ReportDrawer from './ReportDrawer'; // Importa el componente del Drawer
import ReportTree from './ReportTree'; // Importa el componente del Drawer2
import ReportDrawerVB from './ReportDrawerVB';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
const Insumo = () => {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatuses, setSelectedStatuses] = useState(['Entregado', 'Pendiente', 'Programado']);
  const [open, setOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [openDrawer2, setOpenDrawer2] = useState(false);
  const [openDrawer3VB, setOpenDrawer3VB] = useState(false);
  const [selectedReport2, setSelectedReport2] = useState(null);
  const [selectedReport3, setSelectedReport3] = useState(null);

  const appName = 'reporteVB';
  const permissions = useSelector((state) => selectAppPermissions(state, appName));
  const permissionAccess = permissions.priv_access === 'Y';


  const appNameR = 'reportes';
  const permissionsR = useSelector((state) => selectAppPermissions(state, appNameR));
  const permissionInsertR = permissionsR.priv_insert === 'Y';
  const permissionUpdateR = permissionsR.priv_update === 'Y';

  useEffect(() => {
    fetchReports();
  }, []);

  const getButtonColor = (checkbox) => {
    if (checkbox === 1) {
      return 'green';
    } else {
      return 'red'; // Color por defecto (azul)
    }
  };

  const fetchReports = async () => {
    try {
      const response = await getReports();
      if (response.status === 200) {
        setReports(response.data.data);
        setFilteredReports(response.data.data);
      } else {
        console.error('Error al obtener los reportes:', response.message);
      }
    } catch (error) {
      console.error('Error al obtener los reportes:', error);
    } finally {
      setLoading(false);
    }
  };

  const getColorClass = (oidEstado) => {
    switch (oidEstado) {
      case 4:
        return 'card-white';
      case 3:
        return 'card-orange';
      case 2:
        return 'card-yellow';
      case 1:
        return 'card-green';
      default:
        return 'card-white';
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    handleSearch(e.target.value, selectedStatuses);
  };

  const handleSelectChange = (values) => {
    setSelectedStatuses(values);
    handleSearch(searchTerm, values);
  };

  const handleSearch = (term, statuses) => {
    const mappedStatuses = statuses.map(status => status === 'Pendiente' ? 'Pendiente de Revisión' : status);
    const filtered = reports.filter((report) =>
      report.nombre_modulo.toLowerCase().includes(term.toLowerCase()) &&
      mappedStatuses.includes(report.estado)
    );
    setFilteredReports(filtered);
  };

  const handleClear = () => {
    setSearchTerm('');
    setSelectedStatuses(['Entregado', 'Pendiente', 'Programado']);
    setFilteredReports(reports);
  };

  const getColor = (value) => {
    switch (value) {
      case 'Entregado':
        return 'green';
      case 'Pendiente':
        return 'yellow';
      case 'Programado':
        return 'orange';
      case 'Sin Estado':
        return 'gray';
      default:
        return 'default';
    }
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const color = getColor(value);

    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 2,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '32px',
        }}
      >
        {label}
      </Tag>
    );
  };

  const showDrawer = (report) => {
    setSelectedReport(report);
    setOpen(true);
  };

  const showDrawer2 = (report) => {
    setSelectedReport2(report);
    setOpenDrawer2(true);
  };

  const showDrawer3VB = (report) => {
    setSelectedReport3(report);
    setOpenDrawer3VB(true);
  };

  const onClose = () => {
    setOpen(false);
    fetchReports(); // Actualiza los reportes cuando se cierra el Drawer
  };

  const onCloseDrawer2 = () => {
    setOpenDrawer2(false);
    fetchReports(); // Actualiza los reportes cuando se cierra el Drawer
  };

  const onCloseDrawer3VB = () => {
    setOpenDrawer3VB(false);
    fetchReports(); // Actualiza los reportes cuando se cierra el Drawer
  };

  const handleClick = (report) => {
    if (permissionAccess) {
      showDrawer3VB(report);
    }
  };

  return (
    <Layout className="container">
      <div className="header-filter-container d-flex align-items-center justify-content-between">
        <Header160 />
        <div className="container elemtos">
          <div className="input-group-container">
            <div className="input-group shadow-sm">
              <input
                type="text"
                className="form-control shadow-sm"
                placeholder="Buscar Informe"
                value={searchTerm}
                onChange={handleInputChange}
              />
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleClear}
              >
                <FontAwesomeIcon icon={faBroom} />
              </button>
            </div>
          </div>
          <div className="select-container">
            <Select
              mode="multiple"
              tagRender={tagRender}
              defaultValue={['Entregado', 'Pendiente', 'Programado']}
              style={{
                width: '100%',
              }}
              options={[
                { value: 'Entregado' },
                { value: 'Pendiente', label: 'Pendiente de Revisión' },
                { value: 'Programado' },
              ]}
              onChange={handleSelectChange}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <div className="text-center py-4">
          <Spin size="large" />
        </div>
      ) : (
        <div className="card-container">
          <Row gutter={[16, 16]}>
            {filteredReports.length > 0 ? (
              filteredReports.map((report) => (
                <Col key={report.oid} xs={24} sm={12} md={8} lg={8} xl={8} className="mb-3">
                  <Card
                    hoverable
                    className={`card h-100 redondear ${getColorClass(report.oidEstado)}`}
                    style={{ body: { height: '100%', display: 'flex', flexDirection: 'column' } }}
                  >
                    <div className={`card-header align-items-center text-center ${getColorClass(report.oidEstado)}`}>
                      <h5 className={`card-title fw-bold fs-8 m-0 text-header-color`}>{report.nombre_modulo}</h5>

                      <p className={`card-text text-header-color`}>
                        <small className="card-text text-header-color">Estado: {report.estado}</small>
                      </p>
                    </div>

                    <div className="card-body distribucion">
                      <p className="cumpliminetoporcentaje">
                        <span className='text-muted actualizacion'><strong> Periodo: </strong>{report.periodo}</span>
                      </p>
                      <div className="card-footer text-muted align-items-center justify-content-center mt-auto">
                        <Tooltip title="Cargar Archivos" key="tooltip">
                          <Button
                            onClick={() => showDrawer2(report)}
                            className="m-2 btn-primary-lis"
                            style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                            icon={<PlusCircleOutlined style={{ fontSize: 20, color: '#1890ff' }} />}
                          />
                        </Tooltip>

                        {permissionUpdateR && [
                          <span className="separator" key="separator">|</span>,
                          <Tooltip title="Actualizar Registro">
                            <Button
                              onClick={() => showDrawer(report)}
                              className="m-2 btn-primary-lis"
                              style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                              icon={<SettingOutlined style={{ fontSize: 20, color: '#1890ff' }} />}
                            />
                          </Tooltip>,
                        ]}
                        {[
                          <span className="separator" key="separator">|</span>,
                          <Tooltip title="Supervisor Técnico del Convenio" key="tooltip">
                            <Button
                              onClick={() => handleClick(report)} // Pasa `report` a `handleClick`
                              className="m-2 btn-primary-lis"
                              style={{
                                background: 'none',
                                border: 'none',
                                color: 'inherit',
                                padding: 0,
                                font: 'inherit',
                                cursor: permissionAccess ? 'pointer' : 'default' // Cambia el cursor según el permiso
                              }}
                              icon={
                                <CheckSquareOutlined
                                  style={{
                                    fontSize: 20,
                                    color: getButtonColor(report.checkbox)
                                  }}
                                />
                              }
                              disabled={!permissionAccess} // Desactiva el botón si no hay permiso
                            />
                          </Tooltip>
                        ]}
                      </div>

                    </div>
                  </Card>
                </Col>
              ))
            ) : (
              <p className="text-center">No se encontraron reportes.</p>
            )}
          </Row>
        </div>
      )}
      <ReportDrawer open={open} onClose={onClose} reportData={selectedReport || {}} />
      <ReportDrawerVB open={openDrawer3VB} onClose={onCloseDrawer3VB} reportData={selectedReport3 || {}} />
      <ReportTree open={openDrawer2} onClose={onCloseDrawer2} reportData={selectedReport2 || {}} />
    </Layout>
  );
};

export default Insumo;
