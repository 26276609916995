import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/proyecto-municipio';

const getProyectosPorMunicipio = async (departamento_id) => {
  try {
    const response = await axios.get(`${baseUrl}${endpoint}`, {
      params: { departamento_id }
    });
    return {
      status: response.status,
      data: response.data.data,
    };
  } catch (error) {
    console.error('Error al obtener los datos:', error);
    throw error;
  }
};

export { getProyectosPorMunicipio };
